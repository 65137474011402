/*!
 *
 * Dasha - Bootstrap Admin Template
 *
 * Version: 1.0.0
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

/**
 * Preloader
 */
.pace {
	position: fixed;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	user-select: none;
	z-index: 9000;
	height: 4px;
	width: 180px;
	overflow: hidden;
	opacity: 1;
	transition: opacity .2s ease; }
	.pace .pace-progress {
		position: absolute;
		top: 0;
		right: 100%;
		height: 100%;
		width: 100%;
		z-index: 10000;
		border-radius: 3px;
		transform: translate3d(0, 0, 0);
		background-color: #1E88E5;
		background-image: -webkit-linear-gradient(135deg, #673AB7 0, #2196F3 100%);
		/* Chrome 10-25, Safari 5.1-6 */
		background-image: linear-gradient(135deg, #673AB7 0, #2196F3 100%);
		/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
	.pace.pace-inactive {
		opacity: 0; }

.pace-running:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f6fbfe;
	z-index: 8999; }

body {
	opacity: 0; }

/**
 * Bootstrap Custom
 */
.progress.progress-sm {
	height: 16px; }

.progress.progress-xs {
	border-radius: 3px;
	border: 0;
	height: 4px; }

.badge-rounded {
	display: inline-block;
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 12px;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline; }

.card-img-overlay-bottom {
	top: 50%; }

.form-control-inverse {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0.7);
	color: #fff; }
	.form-control-inverse:focus {
		background-color: transparent;
		color: #fff; }
	.form-control-inverse::-moz-placeholder {
		color: #fff;
		opacity: 1; }
	.form-control-inverse:-ms-input-placeholder {
		color: #fff; }
	.form-control-inverse::-webkit-input-placeholder {
		color: #fff; }

@media all and (-ms-high-contrast: none) {
	.align-items-center.align-items-center-ie {
		height: 1px;
		min-height: 100%; } }

/**
 * Bootstrap Reset
 */
html, body {
	direction: ltr;
	width: 100%;
	height: 100%;
	min-width: 320px;
	background-color: #ffffff;
	font-size: 14px; }

body {
	font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	color: #59676b;
	font-size: 1rem; }

.text-muted {
	color: #a2a2a2 !important; }

code {
	background-color: rgba(160, 160, 160, 0.12);
	color: #F06292; }
	code + code {
		margin-left: .25rem; }

.close {
	text-shadow: 0 0 0 #000;
	color: #59676b; }

.btn {
	outline: none !important;
	border-radius: 2px;
	font-size: .95rem;
	font-family: inherit; }
	.btn:active, .btn.active {
		box-shadow: 0 0 0 #000; }

.btn-primary {
	color: #fff;
	background-color: #1E88E5;
	border-color: #1E88E5; }
	.btn-primary:hover {
		color: #fff;
		background-color: #197dd6;
		border-color: #197dd6; }
	.btn-primary:focus, .btn-primary.focus {
		box-shadow: 0 0 0 2px rgba(30, 136, 229, 0.5); }
	.btn-primary.disabled, .btn-primary:disabled {
		background-color: #1E88E5;
		border-color: #1E88E5; }
	.btn-primary:active, .btn-primary.active,
	.show > .btn-primary.dropdown-toggle {
		color: #fff;
		background-color: #197dd6;
		background-image: none;
		border-color: #197dd6; }

.btn-secondary {
	color: #59676b;
	background-color: #fff;
	border-color: #ECEFF1; }
	.btn-secondary:hover {
		color: #59676b;
		background-color: whitesmoke;
		border-color: #e0e5e8; }
	.btn-secondary:focus, .btn-secondary.focus {
		box-shadow: 0 0 0 2px rgba(236, 239, 241, 0.5); }
	.btn-secondary.disabled, .btn-secondary:disabled {
		background-color: #fff;
		border-color: #ECEFF1; }
	.btn-secondary:active, .btn-secondary.active,
	.show > .btn-secondary.dropdown-toggle {
		color: #59676b;
		background-color: whitesmoke;
		background-image: none;
		border-color: #e0e5e8; }

.btn-success {
	color: #fff;
	background-color: #4CAF50;
	border-color: #4CAF50; }
	.btn-success:hover {
		color: #fff;
		background-color: #46a149;
		border-color: #46a149; }
	.btn-success:focus, .btn-success.focus {
		box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5); }
	.btn-success.disabled, .btn-success:disabled {
		background-color: #4CAF50;
		border-color: #4CAF50; }
	.btn-success:active, .btn-success.active,
	.show > .btn-success.dropdown-toggle {
		color: #fff;
		background-color: #46a149;
		background-image: none;
		border-color: #46a149; }

.btn-info {
	color: #fff;
	background-color: #03A9F4;
	border-color: #03A9F4; }
	.btn-info:hover {
		color: #fff;
		background-color: #039be0;
		border-color: #039be0; }
	.btn-info:focus, .btn-info.focus {
		box-shadow: 0 0 0 2px rgba(3, 169, 244, 0.5); }
	.btn-info.disabled, .btn-info:disabled {
		background-color: #03A9F4;
		border-color: #03A9F4; }
	.btn-info:active, .btn-info.active,
	.show > .btn-info.dropdown-toggle {
		color: #fff;
		background-color: #039be0;
		background-image: none;
		border-color: #039be0; }

.btn-warning {
	color: #fff;
	background-color: #FF9800;
	border-color: #FF9800; }
	.btn-warning:hover {
		color: #fff;
		background-color: #eb8c00;
		border-color: #eb8c00; }
	.btn-warning:focus, .btn-warning.focus {
		box-shadow: 0 0 0 2px rgba(255, 152, 0, 0.5); }
	.btn-warning.disabled, .btn-warning:disabled {
		background-color: #FF9800;
		border-color: #FF9800; }
	.btn-warning:active, .btn-warning.active,
	.show > .btn-warning.dropdown-toggle {
		color: #fff;
		background-color: #eb8c00;
		background-image: none;
		border-color: #eb8c00; }

.btn-danger {
	color: #fff;
	background-color: #FF5252;
	border-color: #FF5252; }
	.btn-danger:hover {
		color: #fff;
		background-color: #ff3e3e;
		border-color: #ff3e3e; }
	.btn-danger:focus, .btn-danger.focus {
		box-shadow: 0 0 0 2px rgba(255, 82, 82, 0.5); }
	.btn-danger.disabled, .btn-danger:disabled {
		background-color: #FF5252;
		border-color: #FF5252; }
	.btn-danger:active, .btn-danger.active,
	.show > .btn-danger.dropdown-toggle {
		color: #fff;
		background-color: #ff3e3e;
		background-image: none;
		border-color: #ff3e3e; }

.text-primary {
	color: #1E88E5 !important; }

.text-success {
	color: #4CAF50 !important; }

.text-info {
	color: #03A9F4 !important; }

.text-warning {
	color: #FF9800 !important; }

.text-danger {
	color: #FF5252 !important; }

@media only screen and (max-width: 1199px) {
	input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
		-webkit-appearance: none; } }

.form-check-input {
	margin-right: .5rem; }

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .col-form-label,
.has-success .form-check-label,
.has-success .custom-control {
	color: #4CAF50; }

.has-success .form-control {
	border-color: #4CAF50; }

.has-success .input-group-addon {
	color: #4CAF50;
	border-color: #4CAF50;
	background-color: #d9eeda; }

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .col-form-label,
.has-warning .form-check-label,
.has-warning .custom-control {
	color: #FF9800; }

.has-warning .form-control {
	border-color: #FF9800; }

.has-warning .input-group-addon {
	color: #FF9800;
	border-color: #FF9800;
	background-color: #ffeacc; }

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
	color: #FF5252; }

.has-danger .form-control {
	border-color: #FF5252; }

.has-danger .input-group-addon {
	color: #FF5252;
	border-color: #FF5252;
	background-color: white; }

.custom-control-indicator {
	top: .25rem;
	width: 1.1rem;
	height: 1.1rem;
	background-color: rgba(160, 160, 160, 0.25); }

.custom-select {
	background-image: url("../img/custom-select-angle-down.svg");
	-webkit-background-size: 12px 16px;
	background-size: 12px 16px; }

.table {
	font-weight: 400;
	margin-bottom: 2rem; }
	.table > thead > tr > th {
		border-bottom-width: 1px;
		border-color: rgba(162, 162, 162, 0.16);
		position: relative;
		vertical-align: bottom;
		text-overflow: ellipsis;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0;
		/*height: 48px;*/
		font-size: 12px;

		padding-bottom: 8px !important; }
	.table > tbody > tr > td {
		position: relative;
		vertical-align: middle;
		border-top: 1px solid rgba(0, 0, 0, 0.045); }
	.table > tbody + tbody {
		border-bottom-width: 1px; }
	.table .table {
		background-color: transparent; }

.table-hover > tbody > tr:hover {
	background-color: rgba(162, 162, 162, 0.06); }

.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: rgba(162, 162, 162, 0.06); }

.table-bordered {
	border-color: rgba(162, 162, 162, 0.26); }
	.table-bordered > thead > tr > th,
	.table-bordered > thead > tr > td {
		border-bottom-width: 1px; }
	.table-bordered > tbody > tr > td,
	.table-bordered > tbody > tr > th,
	.table-bordered > tfoot > tr > td,
	.table-bordered > tfoot > tr > th,
	.table-bordered > thead > tr > td,
	.table-bordered > thead > tr > th {
		border-color: rgba(162, 162, 162, 0.26); }

.table-active,
.table-active > th,
.table-active > td {
	background-color: white; }

.table-hover .table-active:hover {
	background-color: #f2f2f2; }
	.table-hover .table-active:hover > td,
	.table-hover .table-active:hover > th {
		background-color: #f2f2f2; }

.table-success,
.table-success > th,
.table-success > td {
	background-color: #99d39b; }

.table-hover .table-success:hover {
	background-color: #87cb8a; }
	.table-hover .table-success:hover > td,
	.table-hover .table-success:hover > th {
		background-color: #87cb8a; }

.table-info,
.table-info > th,
.table-info > td {
	background-color: #6acffd; }

.table-hover .table-info:hover {
	background-color: #51c7fd; }
	.table-hover .table-info:hover > td,
	.table-hover .table-info:hover > th {
		background-color: #51c7fd; }

.table-warning,
.table-warning > th,
.table-warning > td {
	background-color: #ffc570; }

.table-hover .table-warning:hover {
	background-color: #ffbb57; }
	.table-hover .table-warning:hover > td,
	.table-hover .table-warning:hover > th {
		background-color: #ffbb57; }

.table-danger,
.table-danger > th,
.table-danger > td {
	background-color: #ffc2c2; }

.table-hover .table-danger:hover {
	background-color: #ffa9a9; }
	.table-hover .table-danger:hover > td,
	.table-hover .table-danger:hover > th {
		background-color: #ffa9a9; }

tr.active, tr.success, tr.info, tr.warning, tr.danger {
	color: rgba(0, 0, 0, 0.67); }
	tr.active > td, tr.success > td, tr.info > td, tr.warning > td, tr.danger > td {
		border-top: 0 !important; }

.progress .progress-bar {
	transition: width .5s ease; }

.progress-bar {
	background-color: #1E88E5; }

.progress-bar-info {
	background-color: #03A9F4; }

.progress-bar-success {
	background-color: #4CAF50; }

.progress-bar-warning {
	background-color: #FF9800; }

.progress-bar-danger {
	background-color: #FF5252; }

.alert-primary {
	background-color: #1E88E5;
	border-color: #1E88E5;
	color: #fff; }
	.alert-primary hr {
		border-top-color: #187bd1; }
	.alert-primary .alert-link {
		color: #e6e6e6; }
	.alert-primary a, .alert-primary .alert-link {
		color: rgba(255, 255, 255, 0.58); }

.alert-success {
	background-color: #4CAF50;
	border-color: #4CAF50;
	color: #fff; }
	.alert-success hr {
		border-top-color: #449d48; }
	.alert-success .alert-link {
		color: #e6e6e6; }
	.alert-success a, .alert-success .alert-link {
		color: rgba(255, 255, 255, 0.58); }

.alert-info {
	background-color: #03A9F4;
	border-color: #03A9F4;
	color: #fff; }
	.alert-info hr {
		border-top-color: #0398db; }
	.alert-info .alert-link {
		color: #e6e6e6; }
	.alert-info a, .alert-info .alert-link {
		color: rgba(255, 255, 255, 0.58); }

.alert-warning {
	background-color: #FF9800;
	border-color: #FF9800;
	color: #fff; }
	.alert-warning hr {
		border-top-color: #e68900; }
	.alert-warning .alert-link {
		color: #e6e6e6; }
	.alert-warning a, .alert-warning .alert-link {
		color: rgba(255, 255, 255, 0.58); }

.alert-danger {
	background-color: #FF5252;
	border-color: #FF5252;
	color: #fff; }
	.alert-danger hr {
		border-top-color: #ff3939; }
	.alert-danger .alert-link {
		color: #e6e6e6; }
	.alert-danger a, .alert-danger .alert-link {
		color: rgba(255, 255, 255, 0.58); }

.alert-dismissable .close, .alert-dismissible .close {
	color: rgba(0, 0, 0, 0.75); }

.jumbotron {
	padding: 1rem;
	border: 1px solid rgba(162, 162, 162, 0.12);
	background-color: #fff; }
	@media only screen and (min-width: 768px) {
		.jumbotron {
			padding: 2rem; } }

.card .card-header {
	background-color: transparent; }

.card-primary {
	background-color: #1E88E5;
	border-color: #1E88E5; }
	.card-primary .card-header,
	.card-primary .card-footer {
		background-color: transparent; }

.card-success {
	background-color: #4CAF50;
	border-color: #4CAF50; }
	.card-success .card-header,
	.card-success .card-footer {
		background-color: transparent; }

.card-info {
	background-color: #03A9F4;
	border-color: #03A9F4; }
	.card-info .card-header,
	.card-info .card-footer {
		background-color: transparent; }

.card-warning {
	background-color: #FF9800;
	border-color: #FF9800; }
	.card-warning .card-header,
	.card-warning .card-footer {
		background-color: transparent; }

.card-danger {
	background-color: #FF5252;
	border-color: #FF5252; }
	.card-danger .card-header,
	.card-danger .card-footer {
		background-color: transparent; }

.card-accordion .card .card-header {
	padding: 12px 24px; }
	.card-accordion .card .card-header a {
		color: inherit;
		text-decoration: none; }
		.card-accordion .card .card-header a:hover, .card-accordion .card .card-header a:focus {
			color: #1E88E5; }

.card-accordion .card .card-title a {
	text-decoration: none; }

.list-group .list-group-item {
	color: inherit;
	background-color: transparent;
	border-color: rgba(162, 162, 162, 0.12);
	padding: 1rem 1.5rem; }
	.list-group .list-group-item.active {
		color: #fff; }
	.list-group .list-group-item.disabled, .list-group .list-group-item.disabled:focus, .list-group .list-group-item.disabled:hover {
		background-color: rgba(162, 162, 162, 0.12);
		color: inherit; }
	.card > .list-group .list-group-item {
		border-top: 0; }

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
	background-color: transparent;
	color: #1E88E5; }

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
	background-color: #03A9F4;
	border-color: #03A9F4; }

.breadcrumb {
	font-weight: normal;
	border-radius: 0;
	color: #a2a2a2;
	padding: 20px 20px;
	background-color: #fff;
	border-bottom: 1px solid rgba(162, 162, 162, 0.16);
	margin-bottom: 2rem; }
	.breadcrumb .breadcrumb-item::before {
		color: #ccc; }
	.breadcrumb.breadcrumb-transparent {
		background-color: transparent; }

.dropdown-menu {
	top: 50%;
	border: 1px solid #ECEFF1;
	border-radius: 1px;
	line-height: 1;
	min-width: 12rem;
	box-shadow: 0px 5px 25px 0px rgba(123, 123, 123, 0.15); }
	.dropdown-menu .dropdown-item {
		padding: 1rem; }
		.dropdown-menu .dropdown-item > em {
			margin-right: 1rem;
			color: #a2a2a2; }
		.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:active {
			background-color: rgba(162, 162, 162, 0.1);
			color: inherit; }
		.dropdown-menu .dropdown-item.active {
			background-color: #1E88E5;
			color: #fff; }
			.dropdown-menu .dropdown-item.active > em {
				color: #fff; }
	.dropdown-menu .dropdown-divider {
		margin: .5rem 0;
		background-color: rgba(162, 162, 162, 0.16); }
	.dropdown-menu .dropdown-header {
		color: #a1a2a3;
		padding: 1rem 1rem .5rem 1rem; }

.navbar,
.navbar .dropdown-menu {
	filter: none !important; }

.badge-primary {
	background-color: #1E88E5; }
	.badge-primary[href]:focus, .badge-primary[href]:hover {
		background-color: #166dba; }

.badge-success {
	background-color: #4CAF50; }
	.badge-success[href]:focus, .badge-success[href]:hover {
		background-color: #3d8b40; }

.badge-info {
	background-color: #03A9F4; }
	.badge-info[href]:focus, .badge-info[href]:hover {
		background-color: #0286c2; }

.badge-warning {
	background-color: #FF9800; }
	.badge-warning[href]:focus, .badge-warning[href]:hover {
		background-color: #cc7a00; }

.badge-danger {
	background-color: #FF5252; }
	.badge-danger[href]:focus, .badge-danger[href]:hover {
		background-color: #ff1f1f; }

.nav-tabs {
	border-color: rgba(162, 162, 162, 0.5); }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	border-color: rgba(162, 162, 162, 0.5) rgba(162, 162, 162, 0.5) transparent rgba(162, 162, 162, 0.5); }

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	color: #1E88E5;
	border-color: rgba(162, 162, 162, 0.5) rgba(162, 162, 162, 0.5) transparent rgba(162, 162, 162, 0.5); }

.page-link {
	background: transparent;
	border-color: rgba(162, 162, 162, 0.5); }

.page-item.disabled .page-link {
	background-color: rgba(160, 160, 160, 0.25);
	border-color: rgba(162, 162, 162, 0.5); }

.page-link:focus, .page-link:hover {
	border-color: #187bd1;
	background: #1E88E5;
	color: #fff; }

/**
 * Easy Pie Chart
 */
.easypie-chart {
	display: inline-block;
	position: relative;
	padding: 0 6px; }
	.easypie-chart span,
	.easypie-chart .percentage {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		margin-left: -50%;
		height: 30px;
		margin-top: -15px;
		line-height: 25px;
		text-align: center; }
	.easypie-chart span {
		font-size: 20px; }
	.easypie-chart canvas {
		max-width: 100%; }

/**
 * Flot Chart
 */
.flot-chart {
	display: block;
	width: 100%;
	font-size: 12px; }
	@media only screen and (min-width: 768px) {
		.flot-chart {
			font-size: 14px; } }
	.flot-chart .legend > div,
	.flot-chart .legend > table {
		top: 0 !important;
		background-color: transparent !important;
		color: inherit !important; }
	.flot-chart .legend > table tr td {
		padding: 3px; }
	.flot-chart .legend > table tr td:first-child {
		padding-left: 3px; }
	.flot-chart .legend > table tr td:last-child {
		padding-right: 3px; }
	.flot-chart .legend > table tr + tr td {
		padding-top: 0; }
	.flot-chart .legend > div:first-child {
		border-color: rgba(0, 0, 0, 0.1) !important; }
	.flot-chart .legend .legendColorBox > div,
	.flot-chart .legend .legendColorBox > div > div {
		border-radius: 400px; }
	.flot-chart .legend .legendColorBox > div {
		border-color: rgba(162, 162, 162, 0.46) !important; }
	.flot-chart.flot-legend-left .legend > div,
	.flot-chart.flot-legend-left .legend > table {
		left: 0 !important; }
	.flot-chart:not([height]) {
		height: 120px; }
	.flot-chart.flot-chart-lg {
		height: 275px; }
	.flot-chart.flot-chart-md {
		height: 190px; }
	.flot-chart.flot-chart-sm {
		height: 80px; }
	.flot-chart.flot-chart-xs {
		height: 60px; }

.flot-chart-content {
	width: 100%;
	height: 100%; }

.flot-pie-label {
	padding: 3px 5px;
	font-size: 10px;
	text-align: center;
	color: #fff; }

#flotTip,
.flotTip {
	position: relative;
	padding: 8px 16px;
	font-size: 13px !important;
	font-weight: 600;
	border-radius: 2px !important;
	border-color: transparent !important;
	background-color: #455A64 !important;
	color: #fff;
	z-index: 5; }

/**
 * Sparklines
 */
.jqstooltip {
	width: auto !important;
	height: auto !important; }

/**
 * Animations
 */
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both; }

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite; }

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s; }

@-webkit-keyframes fadeInRightShort {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(18px, 0, 0);
		transform: translate3d(18px, 0, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none; } }

@keyframes fadeInRightShort {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(18px, 0, 0);
		-ms-transform: translate3d(18px, 0, 0);
		transform: translate3d(18px, 0, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none; } }

.fadeInRightShort {
	-webkit-animation-name: fadeInRightShort;
	animation-name: fadeInRightShort; }

@-webkit-keyframes fadeOutRightShort {
	from {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(18px, 0, 0);
		transform: translate3d(18px, 0, 0); } }

@keyframes fadeOutRightShort {
	from {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(18px, 0, 0);
		transform: translate3d(18px, 0, 0); } }

.fadeOutRightShort {
	-webkit-animation-name: fadeOutRightShort;
	animation-name: fadeOutRightShort; }

@-webkit-keyframes fadeInLeftShort {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-18px, 0, 0);
		transform: translate3d(-18px, 0, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none; } }

@keyframes fadeInLeftShort {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-18px, 0, 0);
		-ms-transform: translate3d(-18px, 0, 0);
		transform: translate3d(-18px, 0, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none; } }

.fadeInLeftShort {
	-webkit-animation-name: fadeInLeftShort;
	animation-name: fadeInLeftShort; }

@-webkit-keyframes fadeOutLeftShort {
	from {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(-18px, 0, 0);
		transform: translate3d(-18px, 0, 0); } }

@keyframes fadeOutLeftShort {
	from {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(-18px, 0, 0);
		transform: translate3d(-18px, 0, 0); } }

.fadeOutLeftShort {
	-webkit-animation-name: fadeOutLeftShort;
	animation-name: fadeOutLeftShort; }

@-webkit-keyframes fadeOutUpShort {
	0% {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
	0% {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		-ms-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
	-webkit-animation-name: fadeOutUpShort;
	animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none; } }

@keyframes fadeInUpShort {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		-ms-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none; } }

.fadeInUpShort {
	-webkit-animation-name: fadeInUpShort;
	animation-name: fadeInUpShort; }

@-webkit-keyframes fadeInDownShort {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none; } }

@keyframes fadeInDownShort {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0); }
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none; } }

.fadeInDownShort {
	-webkit-animation-name: fadeInDownShort;
	animation-name: fadeInDownShort; }

@-webkit-keyframes fadeOutDownShort {
	from {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0); } }

@keyframes fadeOutDownShort {
	from {
		opacity: 1; }
	100% {
		opacity: 0;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0); } }

.fadeOutDownShort {
	-webkit-animation-name: fadeOutDownShort;
	animation-name: fadeOutDownShort; }

@-webkit-keyframes zoomInShort {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.95, 0.95, 0.95);
		transform: scale3d(0.95, 0.95, 0.95); }
	50% {
		opacity: 1; } }

@keyframes zoomInShort {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.95, 0.95, 0.95);
		-ms-transform: scale3d(0.95, 0.95, 0.95);
		transform: scale3d(0.95, 0.95, 0.95); }
	50% {
		opacity: 1; } }

.zoomInShort {
	-webkit-animation-name: zoomInShort;
	animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
	0% {
		opacity: 1; }
	50% {
		opacity: 0;
		-webkit-transform: scale3d(0.5, 0.5, 0.5);
		transform: scale3d(0.5, 0.5, 0.5); }
	100% {
		opacity: 0; } }

@keyframes zoomBack {
	0% {
		opacity: 1; }
	50% {
		opacity: 0;
		-webkit-transform: scale3d(0.5, 0.5, 0.5);
		-ms-transform: scale3d(0.5, 0.5, 0.5);
		transform: scale3d(0.5, 0.5, 0.5); }
	100% {
		opacity: 0; } }

.zoomBack {
	-webkit-animation-name: zoomBack;
	animation-name: zoomBack; }

/**
 * Buttons Extras
 */
.input-group-btn > .btn {
	border-color: rgba(0, 0, 0, 0.26); }

.btn-shadow {
	box-shadow: 0px 5px 25px 0px rgba(123, 123, 123, 0.15); }

.btn-transparent {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	border-radius: 20px; }

.btn-flat {
	border-color: transparent !important;
	border-radius: 2px;
	background-color: transparent;
	text-transform: uppercase; }
	.btn-flat:active, .btn-flat.active {
		box-shadow: 0 0 0 #000; }
	.btn-flat.btn-primary {
		color: #1E88E5; }
		.btn-flat.btn-primary:hover, .btn-flat.btn-primary:focus, .btn-flat.btn-primary:active {
			color: #fff; }
		.btn-flat.btn-primary:focus {
			background-color: #1E88E5; }
		.btn-flat.btn-primary[disabled], .btn-flat.btn-primary.disabled {
			color: rgba(30, 136, 229, 0.75) !important; }
	.btn-flat.btn-success {
		color: #4CAF50; }
		.btn-flat.btn-success:hover, .btn-flat.btn-success:focus, .btn-flat.btn-success:active {
			color: #fff; }
		.btn-flat.btn-success:focus {
			background-color: #4CAF50; }
		.btn-flat.btn-success[disabled], .btn-flat.btn-success.disabled {
			color: rgba(76, 175, 80, 0.75) !important; }
	.btn-flat.btn-danger {
		color: #FF5252; }
		.btn-flat.btn-danger:hover, .btn-flat.btn-danger:focus, .btn-flat.btn-danger:active {
			color: #fff; }
		.btn-flat.btn-danger:focus {
			background-color: #FF5252; }
		.btn-flat.btn-danger[disabled], .btn-flat.btn-danger.disabled {
			color: rgba(255, 82, 82, 0.75) !important; }
	.btn-flat.btn-warning {
		color: #FF9800; }
		.btn-flat.btn-warning:hover, .btn-flat.btn-warning:focus, .btn-flat.btn-warning:active {
			color: #fff; }
		.btn-flat.btn-warning:focus {
			background-color: #FF9800; }
		.btn-flat.btn-warning[disabled], .btn-flat.btn-warning.disabled {
			color: rgba(255, 152, 0, 0.75) !important; }
	.btn-flat.btn-info {
		color: #03A9F4; }
		.btn-flat.btn-info:hover, .btn-flat.btn-info:focus, .btn-flat.btn-info:active {
			color: #fff; }
		.btn-flat.btn-info:focus {
			background-color: #03A9F4; }
		.btn-flat.btn-info[disabled], .btn-flat.btn-info.disabled {
			color: rgba(3, 169, 244, 0.75) !important; }
	.btn-flat.text-white {
		color: #fff !important; }
	.btn-flat[disabled], .btn-flat.disabled {
		background-color: transparent !important; }
	.btn-flat.btn-flat-icon {
		border-radius: 50%;
		font-size: 24px;
		height: 32px;
		width: 32px;
		padding: 0;
		overflow: hidden;
		color: inherit !important; }
		.btn-flat.btn-flat-icon > em {
			line-height: 32px; }
		.btn-flat.btn-flat-icon:hover, .btn-flat.btn-flat-icon:focus, .btn-flat.btn-flat-icon:active {
			background-color: rgba(158, 158, 158, 0.2) !important; }

.float-right > .btn-flat-icon {
	margin-top: -4px; }

.btn-circle {
	width: 46px;
	height: 46px;
	overflow: hidden;
	border-radius: 50% !important;
	line-height: 46px;
	padding: 0;
	text-align: center; }

.btn-xl {
	padding: 20px 16px;
	font-size: 18px; }

.btn-square {
	border-radius: 0; }

.btn-pill-left, .btn-oval {
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	padding-left: 18px; }

.btn-pill-right, .btn-oval {
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	padding-right: 18px; }

.btn-labeled {
	padding-top: 0;
	padding-bottom: 0;
	text-align: left; }

.btn-label {
	position: relative;
	background: transparent;
	display: inline-block;
	padding: 6px 16px;
	left: -16px;
	border-radius: 3px 0 0 3px; }
	.btn-label.btn-label-right {
		left: auto;
		right: -16px;
		border-radius: 0 3px 3px 0; }
	.btn-label:after {
		content: "";
		position: absolute;
		top: 5px;
		bottom: 5px;
		right: 0;
		width: 1px;
		background-color: rgba(0, 0, 0, 0.1); }
	.btn-label.btn-label-right {
		text-indent: -2px; }
		.btn-label.btn-label-right:after {
			left: 0;
			right: auto; }

.btn-lg .btn-label {
	padding: 10px 20px;
	left: -20px;
	border-radius: 5px 0 0 5px; }
	.btn-lg .btn-label.btn-label-right {
		left: auto;
		right: -20px;
		border-radius: 0 5px 5px 0; }

.btn-sm .btn-label {
	padding: 1px 5px;
	left: -5px;
	border-radius: 2px 0 0 2px; }
	.btn-sm .btn-label.btn-label-right {
		left: auto;
		right: -5px;
		border-radius: 0 2px 2px 0; }

.btn-fw {
	min-width: 80px; }
	.btn-fw.btn-sm {
		min-width: 40px; }
	.btn-fw.btn-md {
		min-width: 60px; }
	.btn-fw.btn-lg {
		min-width: 140px; }

.pagination-rounded .page-item > .page-link {
	border-radius: 50% !important;
	margin-right: 4px;
	margin-left: 4px;
	width: 35px;
	height: 35px;
	line-height: 35px;
	padding: 0;
	text-align: center; }
	.pagination-rounded .page-item > .page-link > span {
		position: relative;
		top: -1px; }

.pagination-rounded.pagination-lg .page-item > .page-link {
	width: 44px;
	height: 44px;
	line-height: 44px; }

.float-left.btn-group,
.float-right.btn-group {
	position: relative;
	z-index: 1; }

.btn-gradient.btn-secondary {
	border: 0;
	background: -webkit-linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.btn-gradient.btn-primary {
	border: 0;
	background: -webkit-linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.btn-gradient.btn-info {
	border: 0;
	background: -webkit-linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.btn-gradient.btn-success {
	border: 0;
	background: -webkit-linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.btn-gradient.btn-danger {
	border: 0;
	background: -webkit-linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.btn-gradient.btn-warning {
	border: 0;
	background: -webkit-linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

@charset "UTF-8";
/**
 * Cards
 */
.cardbox {
	position: relative;
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 0px 5px 25px 0px rgba(123, 123, 123, 0.15);
	margin-bottom: 8px; }
	@media only screen and (min-width: 480px) {
		.cardbox {
			margin-bottom: 16px; } }
	@media only screen and (min-width: 992px) {
		.cardbox {
			margin-bottom: 24px; } }
	.cardbox .cardbox-heading {
		padding: 16px;
		margin: 0; }
		.cardbox .cardbox-heading > .cardbox-title {
			margin: 0;
			font-size: 18px; }
		.cardbox .cardbox-heading > .cardbox-icon {
			float: right;
			color: rgba(255, 255, 255, 0.87);
			font-size: 20px; }
		.cardbox .cardbox-heading > small {
			font-size: .85rem;
			color: rgba(162, 162, 162, 0.92);
			letter-spacing: .01em; }
	.cardbox .cardbox-body {
		position: relative;
		padding: 16px; }
	.cardbox .cardbox-footer {
		padding: 16px;
		border-top: 1px solid rgba(162, 162, 162, 0.12); }
	.cardbox .cardbox-item {
		position: relative;
		display: block;
		min-height: 120px; }
		.cardbox .cardbox-item > .cardbox-item-text {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.35);
			margin: 0;
			color: #fff;
			padding: 8px; }
			.cardbox .cardbox-item > .cardbox-item-text a {
				color: inherit; }
		.cardbox .cardbox-item > .cardbox-item-image {
			display: block;
			width: 100%;
			height: 190px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover; }
		.cardbox .cardbox-item.cardbox-media {
			min-height: 280px;
			background-repeat: repeat;
			background-position: 50% 50%;
			background-size: cover;
			background-attachment: scroll;
			background-origin: padding-box; }
			.cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
				padding: 16px;
				font-size: 35px; }
				@media only screen and (min-width: 768px) {
					.cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
						font-size: 45px; } }
				.cardbox .cardbox-item.cardbox-media .cardbox-media-quote > a {
					color: inherit;
					text-decoration: none; }
				.cardbox .cardbox-item.cardbox-media .cardbox-media-quote:before {
					content: '“';
					display: block;
					font-size: 2em;
					line-height: 1;
					margin-top: 0.25em; }
	.cardbox.cardbox-transparent {
		background-color: transparent;
		border: 0;
		box-shadow: 0 0 0 #000; }
	.cardbox .cardbox-offset {
		position: relative;
		padding-bottom: 36px;
		z-index: 10; }
		.cardbox .cardbox-offset > .cardbox-offset-item {
			position: absolute;
			top: -24px;
			left: 15px;
			right: 15px; }
	.cardbox .cardbox-toolbar {
		position: relative;
		width: 100%;
		min-height: 64px;
		font-size: 18px;
		line-height: 64px;
		padding-left: 22px;
		z-index: 2; }
	.cardbox .cardbox-subheader {
		padding: 16px 0 16px 16px;
		line-height: .75em;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: .01em;
		color: rgba(0, 0, 0, 0.54); }
	.cardbox .cardbox-divider {
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
		border: 1px solid rgba(0, 0, 0, 0.12); }
		.cardbox .cardbox-divider + .cardbox-offset {
			margin-top: -10px; }
	.cardbox > .ui-datepicker,
	.cardbox > .ui-datepicker-responsive > .ui-datepicker {
		width: 100%;
		box-shadow: 0 0 0 #000;
		margin: 0; }
		.cardbox > .ui-datepicker > table,
		.cardbox > .ui-datepicker-responsive > .ui-datepicker > table {
			width: 100%; }
	.cardbox .editable-wrap {
		width: 100%; }
	.cardbox > .list-group > .list-group-item {
		border-left: 0;
		border-right: 0; }
		.cardbox > .list-group > .list-group-item:first-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0; }
		.cardbox > .list-group > .list-group-item:last-child {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
			border-bottom: 0; }
	.cardbox > .table-responsive > .table,
	.cardbox > .table {
		margin-bottom: 0; }
	.cardbox > .table-responsive {
		border: 0; }
	.cardbox > .btn {
		border-radius: 0;
		width: 100%;
		padding-top: 16px;
		padding-bottom: 16px;
		text-align: center; }
		.cardbox > .btn:last-child {
			border-bottom-right-radius: 2px;
			border-bottom-left-radius: 2px; }
	.cardbox.cardbox-map {
		border: 0; }
		.cardbox.cardbox-map .cardbox-footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			border: 0;
			background-color: transparent;
			padding-bottom: .5rem;
			padding-top: 2.5rem;
			background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75) 35%, white);
			background-repeat: no-repeat; }
	.cardbox.cardbox-flat {
		box-shadow: 0 0 0 #000;
		background-color: transparent !important;
		border: 0; }

/**
 * Container variants
 */
.page-container {
	width: 100%;
	height: 100%; }
	.page-container > * {
		min-height: 100%; }

.container-full {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch; }
	.container-full > .row {
		margin: 0; }
		.container-full > .row > [class*="col-"]:first-child {
			padding-left: 0; }
		.container-full > .row > [class*="col-"]:last-child {
			padding-right: 0; }

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xs {
	padding: .6rem;
	width: auto; }
	@media only screen and (min-width: 480px) {
		.container-fluid,
		.container-lg,
		.container-md,
		.container-sm,
		.container-xs {
			padding: 1.2rem; } }

.container-lg {
	max-width: 1200px; }

.container-md {
	max-width: 992px; }

.container-sm {
	max-width: 768px; }

.container-xs {
	max-width: 480px; }

@media only screen and (max-width: 1199px) {
	.container-unwrap {
		padding: 0; }
		.container-unwrap > .row {
			margin: 0; }
			.container-unwrap > .row > [class*="col-"] {
				padding-left: 0;
				padding-right: 0; }
		.container-unwrap .cardbox {
			margin-bottom: 0; } }

.container-overlap {
	position: relative;
	padding: 2.2rem 1.2rem 4.5rem 1.2rem; }
	.container-overlap + .container-fluid,
	.container-overlap + .container-lg,
	.container-overlap + .container-md,
	.container-overlap + .container-sm,
	.container-overlap + .container-xs {
		padding-top: 0;
		margin-top: -2.2rem; }
		.container-overlap + .container-fluid .push-down,
		.container-overlap + .container-lg .push-down,
		.container-overlap + .container-md .push-down,
		.container-overlap + .container-sm .push-down,
		.container-overlap + .container-xs .push-down {
			display: block;
			height: 3.4rem; }
	.container-overlap:before {
		background-color: inherit;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1; }

/**
 * Dropdowns Extras
 */
.dropdown-card {
	max-width: 20rem; }

.dropdown-scale {
	display: block;
	opacity: 0;
	padding: 0;
	transform: scale(0);
	transition: all .3s;
	backface-visibility: hidden;
	transform-origin: top left; }
	.dropdown-scale.float-right, .dropdown-scale.dropdown-menu-right {
		transform-origin: top right; }

.show > .dropdown-scale {
	opacity: 1;
	transform: scale(1); }

.dropup .dropdown-scale {
	transform-origin: bottom left; }
	.dropup .dropdown-scale.float-right, .dropup .dropdown-scale.dropdown-menu-right {
		transform-origin: bottom right; }

/**
 * Rows - Extras
 */
.row {
	margin-right: -4px;
	margin-left: -4px; }
	.row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
	.row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
	.row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
	.row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
	.row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
	.row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
		padding-right: 4px;
		padding-left: 4px; }
	@media only screen and (min-width: 480px) {
		.row {
			margin-right: -8px;
			margin-left: -8px; }
			.row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
			.row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
			.row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
			.row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
			.row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
			.row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
				padding-right: 8px;
				padding-left: 8px; } }
	@media only screen and (min-width: 992px) {
		.row {
			margin-right: -12px;
			margin-left: -12px; }
			.row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
			.row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
			.row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
			.row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
			.row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
			.row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
				padding-right: 12px;
				padding-left: 12px; } }

.t-grid .t-row {
	width: 100%;
	display: table;
	table-layout: fixed; }

.t-grid .t-col, .t-grid .t-cell {
	display: table-cell; }

/**
 * Modals
 */
.modal-header,
.modal-footer {
	border-color: rgba(162, 162, 162, 0.12); }

.modal.modal-right,
.modal.modal-left,
.modal.modal-bottom,
.modal.modal-top {
	-webkit-overflow-scrolling: touch; }
	.modal.modal-right .modal-dialog,
	.modal.modal-left .modal-dialog,
	.modal.modal-bottom .modal-dialog,
	.modal.modal-top .modal-dialog {
		position: absolute;
		margin: 0;
		max-width: none;
		transition-transform: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
		-webkit-overflow-scrolling: touch; }
		.modal.modal-right .modal-dialog > .modal-content,
		.modal.modal-left .modal-dialog > .modal-content,
		.modal.modal-bottom .modal-dialog > .modal-content,
		.modal.modal-top .modal-dialog > .modal-content {
			border-radius: 0;
			border: 0; }
	.modal.modal-right.fade.show .modal-dialog,
	.modal.modal-left.fade.show .modal-dialog,
	.modal.modal-bottom.fade.show .modal-dialog,
	.modal.modal-top.fade.show .modal-dialog {
		transform: translate(0, 0); }

.modal.fade.modal-right .modal-dialog,
.modal.fade.modal-left .modal-dialog,
.modal.fade.modal-bottom .modal-dialog,
.modal.fade.modal-top .modal-dialog {
	transition-transform: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.modal-right .modal-dialog {
	right: 0;
	bottom: 0;
	top: 0;
	width: 240px; }
	.modal.modal-right .modal-dialog > .modal-content {
		min-height: 100%; }

.modal.modal-right.modal-auto-size .modal-dialog {
	width: 80%; }
	@media only screen and (min-width: 768px) {
		.modal.modal-right.modal-auto-size .modal-dialog {
			width: 50%; } }

.modal.modal-right.fade .modal-dialog {
	transform: translate(100%, 0); }

.modal.modal-left .modal-dialog {
	left: 0;
	bottom: 0;
	top: 0;
	width: 240px; }
	.modal.modal-left .modal-dialog > .modal-content {
		min-height: 100%; }

.modal.modal-left.modal-auto-size .modal-dialog {
	width: 80%; }
	@media only screen and (min-width: 768px) {
		.modal.modal-left.modal-auto-size .modal-dialog {
			width: 50%; } }

.modal.modal-left.fade .modal-dialog {
	transform: translate(-100%, 0); }

.modal.modal-top .modal-dialog {
	right: 0;
	left: 0;
	top: 0;
	width: 100%; }
	.modal.modal-top .modal-dialog > .modal-content {
		width: 100%; }

.modal.modal-top.fade .modal-dialog {
	transform: translate(0, -100%); }

.modal.modal-bottom .modal-dialog {
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	max-height: 240px;
	overflow-y: auto; }
	.modal.modal-bottom .modal-dialog > .modal-content {
		width: 100%; }

.modal.modal-bottom.fade .modal-dialog {
	transform: translate(0, 100%); }

.modal-backdrop {
	opacity: 0;
	will-change: opacity; }
	.modal-backdrop.show {
		transition: opacity .28s ease-in-out; }

.modal-backdrop-light .modal-backdrop {
	background-color: #fff; }
	.modal-backdrop-light .modal-backdrop.show {
		opacity: .9; }

.modal-backdrop-soft .modal-backdrop.show {
	opacity: .3; }

.modal-backdrop {
	position: fixed;
	bottom: 0; }

/**
 * Spinners (Loader.CSS)
 */
.loader-primary .ball-pulse > div,
.loader-primary .ball-grid-pulse > div,
.loader-primary .square-spin > div,
.loader-primary .ball-pulse-rise > div,
.loader-primary .ball-rotate > div,
.loader-primary .ball-rotate > div:before, .loader-primary .ball-rotate > div:after,
.loader-primary .cube-transition > div,
.loader-primary .ball-zig-zag > div,
.loader-primary .ball-zig-zag-deflect > div,
.loader-primary .ball-triangle-path > div,
.loader-primary .ball-scale > div,
.loader-primary .line-scale > div,
.loader-primary .line-scale-party > div,
.loader-primary .ball-scale-multiple > div,
.loader-primary .ball-pulse-sync > div,
.loader-primary .ball-beat > div,
.loader-primary .line-scale-pulse-out > div,
.loader-primary .line-scale-pulse-out-rapid > div,
.loader-primary .ball-spin-fade-loader > div,
.loader-primary .line-spin-fade-loader > div,
.loader-primary .triangle-skew-spin > div,
.loader-primary .ball-grid-beat > div,
.loader-primary .semi-circle-spin > div {
	background-color: #1E88E5; }

.loader-primary .ball-clip-rotate-multiple {
	width: 1px; }
	.loader-primary .ball-clip-rotate-multiple > div {
		border-color: #1E88E5 transparent #1E88E5 transparent; }
		.loader-primary .ball-clip-rotate-multiple > div:last-child {
			border-color: transparent #1E88E5 transparent #1E88E5; }

.loader-primary .ball-clip-rotate > div {
	border-color: #1E88E5;
	border-bottom-color: transparent; }

.loader-primary .ball-clip-rotate-pulse > div:first-child {
	background-color: #1E88E5;
	top: 5px;
	left: -8px; }

.loader-primary .ball-clip-rotate-pulse > div:last-child {
	border-color: #1E88E5 transparent #1E88E5 transparent; }

.loader-primary .square-spin > div,
.loader-primary .ball-scale-ripple > div,
.loader-primary .ball-scale-ripple-multiple > div {
	border-color: #1E88E5; }

.loader-primary .pacman > div:first-of-type {
	border-top-color: #1E88E5;
	border-left-color: #1E88E5;
	border-bottom-color: #1E88E5; }

.loader-primary .pacman > div:nth-child(2) {
	border-top-color: #1E88E5;
	border-left-color: #1E88E5;
	border-bottom-color: #1E88E5; }

.loader-primary .pacman > div:nth-child(3), .loader-primary .pacman > div:nth-child(4), .loader-primary .pacman > div:nth-child(5), .loader-primary .pacman > div:nth-child(6) {
	background-color: #1E88E5; }

.loader-demo {
	padding: 20px 0;
	height: 120px;
	text-align: center; }
	.loader-demo > .loader-inner {
		display: inline-block; }

/**
 * Themes
 */
.theme-default .layout-container .sidebar-container {
	background-color: #263238;
	color: #fff; }

.theme-default .brand-header {
	background-color: #263238;
	color: #fff; }

.theme-default .layout-container .header-container {
	background-color: #fff;
	color: #59676b; }

.theme-default .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-default .sidebar-nav ul > li.active > a {
	border-left-color: #1E88E5; }

.theme-2 .layout-container .sidebar-container {
	background-color: #263238;
	color: #fff; }

.theme-2 .brand-header {
	background-color: #263238;
	color: #fff; }

.theme-2 .layout-container .header-container {
	background-color: #00BFA5;
	color: #fff; }

.theme-2 .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-2 .sidebar-nav ul > li.active > a {
	border-left-color: #00BFA5; }

.theme-3 .layout-container .sidebar-container {
	background-color: #263238;
	color: #fff; }

.theme-3 .brand-header {
	background-color: #263238;
	color: #fff; }

.theme-3 .layout-container .header-container {
	background-color: #00B8D4;
	color: #fff; }

.theme-3 .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-3 .sidebar-nav ul > li.active > a {
	border-left-color: #00B8D4; }

.theme-4 .layout-container .sidebar-container {
	background-color: #263238;
	color: #fff; }

.theme-4 .brand-header {
	background-color: #263238;
	color: #fff; }

.theme-4 .layout-container .header-container {
	background-color: #1976D2;
	color: #fff; }

.theme-4 .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-4 .sidebar-nav ul > li.active > a {
	border-left-color: #1976D2; }

.theme-5 .layout-container .sidebar-container {
	background-color: #263238;
	color: #fff; }

.theme-5 .brand-header {
	background-color: #263238;
	color: #fff; }

.theme-5 .layout-container .header-container {
	background-color: #7C4DFF;
	color: #fff; }

.theme-5 .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-5 .sidebar-nav ul > li.active > a {
	border-left-color: #7C4DFF; }

.theme-6 .layout-container .sidebar-container {
	background-color: #263238;
	color: #fff; }

.theme-6 .brand-header {
	background-color: #30891c;
	color: #fff; }

.theme-6 .layout-container .header-container {
	background-color: #43b02a;
	color: #fff;
}

.theme-6 .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-6 .sidebar-nav ul > li.active > a {
	border-left-color: #43b02a; }
.theme-6 .sidebar-nav ul > li > a:active {
	background-color: #43b02a; }

.theme-default-w .layout-container .sidebar-container {
	background-color: #fff;
	color: #455A64; }

.theme-default-w .brand-header {
	background-color: #fff;
	color: #455A64; }

.theme-default-w .layout-container .header-container {
	background-color: #fff;
	color: #59676b; }

.theme-default-w .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-default-w .sidebar-nav ul > li.active > a {
	border-left-color: #1E88E5; }

.theme-2-w .layout-container .sidebar-container {
	background-color: #fff;
	color: #455A64; }

.theme-2-w .brand-header {
	background-color: #fff;
	color: #455A64; }

.theme-2-w .layout-container .header-container {
	background-color: #00BFA5;
	color: #fff; }

.theme-2-w .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-2-w .brand-header-logo > .nav-icon > svg, .theme-2-w .brand-header-logo > .nav-icon > em,
.theme-2-w .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-2-w .sidebar-nav ul > li > a > .nav-icon > em {
	color: #00BFA5; }

.theme-2-w .sidebar-nav ul > li.active > a {
	border-left-color: #00BFA5; }

.theme-3-w .layout-container .sidebar-container {
	background-color: #fff;
	color: #455A64; }

.theme-3-w .brand-header {
	background-color: #fff;
	color: #455A64; }

.theme-3-w .layout-container .header-container {
	background-color: #00B8D4;
	color: #fff; }

.theme-3-w .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-3-w .brand-header-logo > .nav-icon > svg, .theme-3-w .brand-header-logo > .nav-icon > em,
.theme-3-w .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-3-w .sidebar-nav ul > li > a > .nav-icon > em {
	color: #00B8D4; }

.theme-3-w .sidebar-nav ul > li.active > a {
	border-left-color: #00B8D4; }

.theme-4-w .layout-container .sidebar-container {
	background-color: #fff;
	color: #455A64; }

.theme-4-w .brand-header {
	background-color: #fff;
	color: #455A64; }

.theme-4-w .layout-container .header-container {
	background-color: #1976D2;
	color: #fff; }

.theme-4-w .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-4-w .brand-header-logo > .nav-icon > svg, .theme-4-w .brand-header-logo > .nav-icon > em,
.theme-4-w .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-4-w .sidebar-nav ul > li > a > .nav-icon > em {
	color: #1976D2; }

.theme-4-w .sidebar-nav ul > li.active > a {
	border-left-color: #1976D2; }

.theme-5-w .layout-container .sidebar-container {
	background-color: #fff;
	color: #455A64; }

.theme-5-w .brand-header {
	background-color: #fff;
	color: #455A64; }

.theme-5-w .layout-container .header-container {
	background-color: #7C4DFF;
	color: #fff; }

.theme-5-w .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-5-w .brand-header-logo > .nav-icon > svg, .theme-5-w .brand-header-logo > .nav-icon > em,
.theme-5-w .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-5-w .sidebar-nav ul > li > a > .nav-icon > em {
	color: #7C4DFF; }

.theme-5-w .sidebar-nav ul > li.active > a {
	border-left-color: #7C4DFF; }

.theme-6-w .layout-container .sidebar-container {
	background-color: #fff;
	color: #455A64; }

.theme-6-w .brand-header {
	background-color: #fff;
	color: #455A64; }

.theme-6-w .layout-container .header-container {
	background-color: #43b02a;
	color: #fff; }

.theme-6-w .layout-container .main-container {
	background-color: #f6fbfe; }

.theme-6-w .brand-header-logo > .nav-icon > svg, .theme-6-w .brand-header-logo > .nav-icon > em,
.theme-6-w .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-6-w .sidebar-nav ul > li > a > .nav-icon > em {
	color: #43b02a; }

.theme-6-w .sidebar-nav ul > li.active > a {
	border-left-color: #43b02a; }

.theme-gradient-sidebar-1 .layout-container .sidebar-container {
	background-image: -webkit-linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-sidebar-1 .brand-header-logo > .nav-icon > svg, .theme-gradient-sidebar-1 .brand-header-logo > .nav-icon > em,
.theme-gradient-sidebar-1 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-sidebar-1 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-sidebar-1 .sidebar-nav ul > li.active > a {
	border-left-color: #fff; }

.theme-gradient-sidebar-2 .layout-container .sidebar-container {
	background-image: -webkit-linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-sidebar-2 .brand-header-logo > .nav-icon > svg, .theme-gradient-sidebar-2 .brand-header-logo > .nav-icon > em,
.theme-gradient-sidebar-2 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-sidebar-2 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-sidebar-2 .sidebar-nav ul > li.active > a {
	border-left-color: #fff; }

.theme-gradient-sidebar-3 .layout-container .sidebar-container {
	background-image: -webkit-linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-sidebar-3 .brand-header-logo > .nav-icon > svg, .theme-gradient-sidebar-3 .brand-header-logo > .nav-icon > em,
.theme-gradient-sidebar-3 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-sidebar-3 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-sidebar-3 .sidebar-nav ul > li.active > a {
	border-left-color: #fff; }

.theme-gradient-sidebar-4 .layout-container .sidebar-container {
	background-image: -webkit-linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-sidebar-4 .brand-header-logo > .nav-icon > svg, .theme-gradient-sidebar-4 .brand-header-logo > .nav-icon > em,
.theme-gradient-sidebar-4 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-sidebar-4 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-sidebar-4 .sidebar-nav ul > li.active > a {
	border-left-color: #fff; }

.theme-gradient-sidebar-5 .layout-container .sidebar-container {
	background-image: -webkit-linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-sidebar-5 .brand-header-logo > .nav-icon > svg, .theme-gradient-sidebar-5 .brand-header-logo > .nav-icon > em,
.theme-gradient-sidebar-5 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-sidebar-5 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-sidebar-5 .sidebar-nav ul > li.active > a {
	border-left-color: #fff; }

.theme-gradient-sidebar-6 .layout-container .sidebar-container {
	background-image: -webkit-linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-sidebar-6 .brand-header-logo > .nav-icon > svg, .theme-gradient-sidebar-6 .brand-header-logo > .nav-icon > em,
.theme-gradient-sidebar-6 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-sidebar-6 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-sidebar-6 .sidebar-nav ul > li.active > a {
	border-left-color: #fff; }

.theme-gradient-header-1 .layout-container .header-container {
	background-image: -webkit-linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-header-1 .brand-header-logo > .nav-icon > svg, .theme-gradient-header-1 .brand-header-logo > .nav-icon > em,
.theme-gradient-header-1 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-header-1 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-header-1 .sidebar-nav ul > li.active > a {
	border-left-color: #1E88E5; }

.theme-gradient-header-2 .layout-container .sidebar-container {
	background-color: #fff;
	color: #263238; }

.theme-gradient-header-2 .layout-container .header-container {
	background-image: -webkit-linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-header-2 .sidebar-nav ul > li.active > a {
	border-left-color: #B0BEC5; }

.theme-gradient-header-3 .layout-container .header-container {
	background-image: -webkit-linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-header-3 .brand-header-logo > .nav-icon > svg, .theme-gradient-header-3 .brand-header-logo > .nav-icon > em,
.theme-gradient-header-3 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-header-3 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-header-3 .sidebar-nav ul > li.active > a {
	border-left-color: #03A9F4; }

.theme-gradient-header-4 .layout-container .header-container {
	background-image: -webkit-linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-header-4 .brand-header-logo > .nav-icon > svg, .theme-gradient-header-4 .brand-header-logo > .nav-icon > em,
.theme-gradient-header-4 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-header-4 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-header-4 .sidebar-nav ul > li.active > a {
	border-left-color: #FF5252; }

.theme-gradient-header-5 .layout-container .sidebar-container {
	background-color: #fff;
	color: #263238; }

.theme-gradient-header-5 .layout-container .header-container {
	background-image: -webkit-linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-header-5 .sidebar-nav ul > li.active > a {
	border-left-color: #FF9800; }

.theme-gradient-header-6 .layout-container .header-container {
	background-image: -webkit-linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: #fff; }

.theme-gradient-header-6 .brand-header-logo > .nav-icon > svg, .theme-gradient-header-6 .brand-header-logo > .nav-icon > em,
.theme-gradient-header-6 .sidebar-nav ul > li > a > .nav-icon > svg,
.theme-gradient-header-6 .sidebar-nav ul > li > a > .nav-icon > em {
	color: #fff; }

.theme-gradient-header-6 .sidebar-nav ul > li.active > a {
	border-left-color: #4CAF50; }

.theme-dark {
	background-color: #2f3c43; }
	.theme-dark .layout-container .sidebar-container {
		background-color: #263238;
		color: #fff; }
	.theme-dark .brand-header {
		background-color: #263238;
		color: #fff; }
	.theme-dark .layout-container .header-container {
		background-color: #37474F;
		color: #fff; }
	.theme-dark .layout-container .main-container {
		background-color: #2f3c43;
		color: #f1f1f1; }
	.theme-dark .header-container > nav {
		box-shadow: 0px 5px 25px 0px rgba(23, 23, 23, 0.15); }
	.theme-dark .footer-container {
		background-color: #2f3c43;
		box-shadow: 0px 5px 25px 0px rgba(23, 23, 23, 0.15); }
	.theme-dark .bg-white:not([class*='bg-']), .theme-dark .card-secondary > .card-header:not([class*='bg-']), .theme-dark .card:not([class*='bg-']):not(.card-primary):not(.card-success):not(.card-info):not(.card-warning):not(.card-danger), .theme-dark .card:not([class*='bg-']):not(.card-primary):not(.card-success):not(.card-info):not(.card-warning):not(.card-danger) > .card-header, .theme-dark .card:not([class*='bg-']):not(.card-primary):not(.card-success):not(.card-info):not(.card-warning):not(.card-danger) > .card-footer {
		background-color: #35444c;
		color: inherit; }
	.theme-dark .cardbox {
		background-color: #35444c;
		box-shadow: 0px 5px 25px 0px rgba(23, 23, 23, 0.15); }
	.theme-dark .cardbox.cardbox-map .cardbox-footer {
		background-image: linear-gradient(rgba(55, 71, 79, 0), rgba(55, 71, 79, 0.55) 45%, #37474F); }
	.theme-dark .bg-white {
		background-color: #35444c;
		color: inherit; }
	.theme-dark .well, .theme-dark .jumbotron, .theme-dark .breadcrumb {
		background-color: #35444c;
		color: inherit; }
	.theme-dark .modal-content {
		background-color: #37474F;
		color: #fff; }
	.theme-dark .btn-secondary,
	.theme-dark .note-btn-group .btn-default {
		color: #fff;
		background-color: #37474F;
		border-color: #2d3940; }
		.theme-dark .btn-secondary:hover,
		.theme-dark .note-btn-group .btn-default:hover {
			color: #fff;
			background-color: #2f3c43;
			border-color: #242f34; }
		.theme-dark .btn-secondary:focus, .theme-dark .btn-secondary.focus,
		.theme-dark .note-btn-group .btn-default:focus,
		.theme-dark .note-btn-group .btn-default.focus {
			box-shadow: 0 0 0 2px rgba(45, 57, 64, 0.5); }
		.theme-dark .btn-secondary.disabled, .theme-dark .btn-secondary:disabled,
		.theme-dark .note-btn-group .btn-default.disabled,
		.theme-dark .note-btn-group .btn-default:disabled {
			background-color: #37474F;
			border-color: #2d3940; }
		.theme-dark .btn-secondary:active, .theme-dark .btn-secondary.active,
		.show > .theme-dark .btn-secondary.dropdown-toggle,
		.theme-dark .note-btn-group .btn-default:active,
		.theme-dark .note-btn-group .btn-default.active,
		.show >
		.theme-dark .note-btn-group .btn-default.dropdown-toggle {
			color: #fff;
			background-color: #2f3c43;
			background-image: none;
			border-color: #242f34; }
	.theme-dark .dropdown-menu {
		background-color: #37474F;
		border-color: #2d3940; }
		.theme-dark .dropdown-menu .dropdown-item {
			color: #fff; }
	.theme-dark .show > .dropdown-toggle.btn-secondary, .theme-dark .show > .dropdown-toggle.btn-secondary:hover, .theme-dark .show > .dropdown-toggle.btn-secondary:active, .theme-dark .show > .dropdown-toggle.btn-secondary.active, .theme-dark .show > .dropdown-toggle.btn-secondary:focus {
		color: #fff;
		background-color: #37474F;
		border-color: #2d3940; }
	.theme-dark .form-control, .theme-dark .custom-select, .theme-dark .custom-file-control {
		color: inherit;
		background-color: rgba(240, 240, 240, 0.1);
		border-color: rgba(240, 240, 240, 0.12); }
		.theme-dark .form-control:focus, .theme-dark .custom-select:focus, .theme-dark .custom-file-control:focus {
			border-color: rgba(240, 240, 240, 0.36); }
	.theme-dark .custom-file-control:before {
		background: #37474F;
		color: #fff;
		border-color: rgba(240, 240, 240, 0.12); }
	.theme-dark .form-control[disabled],
	.theme-dark .form-control[readonly],
	.theme-dark fieldset[disabled] .form-control {
		background-color: rgba(240, 240, 240, 0.1); }
	.theme-dark select:not([multiple]) option {
		color: #59676b; }
	.theme-dark .custom-select {
		background-image: url("../img/custom-select-angle-down-white.svg"); }
	.theme-dark .input-group-addon,
	.theme-dark .input-group-button {
		background-color: transparent;
		border: 1px solid rgba(162, 162, 162, 0.26);
		color: inherit; }
	.theme-dark .nav-tabs .nav-item.show .nav-link, .theme-dark .nav-tabs .nav-link.active {
		background: #37474F;
		color: #fff; }
	.theme-dark .note-editor .note-editing-area .note-editable {
		background-color: rgba(240, 240, 240, 0.1); }
	.theme-dark .progress {
		background-color: rgba(240, 240, 240, 0.1); }
	.theme-dark .thead-inverse th {
		background-color: #455A64; }
	.theme-dark .popover {
		background-color: #37474F;
		color: #fff; }
		.theme-dark .popover .popover-title {
			background-color: #41555e;
			border-color: rgba(160, 160, 160, 0.15);
			color: inherit; }
		.theme-dark .popover .popover-content {
			background-color: #37474F;
			border-color: rgba(160, 160, 160, 0.15);
			color: inherit; }
		.theme-dark .popover.bs-tether-element-attached-left::after, .theme-dark .popover.popover-right::after {
			border-right-color: #37474F; }
		.theme-dark .popover.bs-tether-element-attached-right::after, .theme-dark .popover.popover-left::after {
			border-left-color: #37474F; }
		.theme-dark .popover.bs-tether-element-attached-top::after, .theme-dark .popover.popover-bottom::after {
			border-bottom-color: #37474F; }
		.theme-dark .popover.bs-tether-element-attached-bottom::after, .theme-dark .popover.popover-top::after {
			border-top-color: #37474F; }
	.theme-dark .twitter-typeahead .tt-hint {
		color: inherit !important;
		background-color: rgba(240, 240, 240, 0.1) !important;
		border-color: rgba(240, 240, 240, 0.12) !important; }
	.theme-dark .twitter-typeahead .tt-menu {
		background-color: #37474F;
		border: 1px solid rgba(160, 160, 160, 0.5); }
	.theme-dark .twitter-typeahead .tt-suggestion {
		color: #fff; }
		.theme-dark .twitter-typeahead .tt-suggestion:hover {
			background-color: rgba(160, 160, 160, 0.12); }
	.theme-dark .ms-container .ms-selectable {
		background: #37474F; }
		.theme-dark .ms-container .ms-selectable li.ms-elem-selectable {
			color: #fff; }
		.theme-dark .ms-container .ms-selectable li.disabled {
			background-color: transparent;
			color: #6c8a99; }
	.theme-dark .ms-container .ms-selection {
		background: #37474F; }
		.theme-dark .ms-container .ms-selection li.ms-elem-selection {
			color: #fff; }
		.theme-dark .ms-container .ms-selection li.disabled {
			background-color: transparent;
			color: #6c8a99; }
	.theme-dark .select2-container--default .select2-dropdown {
		background-color: #37474F;
		color: #fff; }
		.theme-dark .select2-container--default .select2-dropdown .select2-search__field {
			color: inherit;
			background-color: rgba(240, 240, 240, 0.1);
			border-color: rgba(240, 240, 240, 0.12); }
			.theme-dark .select2-container--default .select2-dropdown .select2-search__field:focus {
				border-color: rgba(240, 240, 240, 0.36); }
	.theme-dark .sidebar-nav ul > li.active > a {
		border-left-color: #1E88E5; }

.preview-theme-default {
	background-color: #fff; }

.preview-theme-2 {
	background-color: #00BFA5; }

.preview-theme-3 {
	background-color: #00B8D4; }

.preview-theme-4 {
	background-color: #1976D2; }

.preview-theme-5 {
	background-color: #7C4DFF; }

.preview-theme-6 {
	background-color: #43b02a; }

.preview-theme-dark {
	background-color: #263238; }

.preview-theme-gradient-1 {
	background-image: -webkit-linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.preview-theme-gradient-2 {
	background-image: -webkit-linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.preview-theme-gradient-3 {
	background-image: -webkit-linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.preview-theme-gradient-4 {
	background-image: -webkit-linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.preview-theme-gradient-5 {
	background-image: -webkit-linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.preview-theme-gradient-6 {
	background-image: -webkit-linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

/**
 * Typography
 */
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
	font-weight: 400;
	line-height: 1.35;
	letter-spacing: -0.02em;
	opacity: 0.54;
	font-size: 0.6em;
	color: inherit; }

h1 > small, h2 > small, h3 > small, h4 > small, h5 > small, h6 > small {
	margin-left: 5px; }

small {
	color: inherit; }

.blockquote {
	padding: 1rem 2rem;
	margin: 0 0 2rem;
	font-size: 1.75rem;
	border-left: .5rem solid #eee; }
	.blockquote p:last-child {
		margin: 0; }
	.blockquote .small, .blockquote footer, .blockquote small {
		display: block;
		font-size: 80%;
		line-height: 1.42857143;
		color: #777; }
		.blockquote .small:before, .blockquote footer:before, .blockquote small:before {
			content: '\2014 \00A0'; }

/**
 * Forms - Note Editor
 */
.note-area > textarea {
	background-image: -webkit-linear-gradient(rgba(162, 162, 162, 0.26) 0.1em, transparent 0.1em);
	background-image: linear-gradient(rgba(162, 162, 162, 0.26) 0.1em, transparent 0.1em);
	background-size: 100% 20px;
	background-color: transparent !important;
	line-height: 20px;
	margin-top: 5px;
	padding: 0;
	padding-bottom: 1px;
	border: none !important; }

.note-area.note-area-margin {
	position: relative; }
	.note-area.note-area-margin > textarea {
		padding-left: 55px; }
	.note-area.note-area-margin:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 45px;
		width: 5px;
		background-color: rgba(255, 152, 0, 0.5); }

/**
 * Forms - Switch
 */
.switch .form-control {
	padding-top: 7px;
	margin-bottom: 0; }

.switch * {
	cursor: pointer; }

.switch input {
	opacity: 0;
	position: absolute;
	z-index: -1; }

.switch {
	margin-right: 8px; }
	.switch span {
		position: relative;
		display: inline-block;
		width: 35px;
		height: 15px;
		background-color: #9e9e9e;
		border: 0;
		border-radius: 8px;
		vertical-align: middle;
		transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s; }
	.switch span:after {
		content: "";
		position: absolute;
		background-color: #fff;
		top: -2px;
		left: 0;
		height: 20px;
		width: 20px;
		border: 0;
		border-radius: 400px;
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
		transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1); }
	.switch.switch-primary input:checked + span {
		background-color: #1E88E5; }
		.switch.switch-primary input:checked + span:after {
			background-color: #1E88E5; }
	.switch.switch-primary.switch-warn input:checked + span {
		background-color: rgba(30, 136, 229, 0.5); }
	.switch.switch-info input:checked + span {
		background-color: #03A9F4; }
		.switch.switch-info input:checked + span:after {
			background-color: #03A9F4; }
	.switch.switch-info.switch-warn input:checked + span {
		background-color: rgba(3, 169, 244, 0.5); }
	.switch.switch-danger input:checked + span {
		background-color: #FF5252; }
		.switch.switch-danger input:checked + span:after {
			background-color: #FF5252; }
	.switch.switch-danger.switch-warn input:checked + span {
		background-color: rgba(255, 82, 82, 0.5); }
	.switch.switch-warning input:checked + span {
		background-color: #FF9800; }
		.switch.switch-warning input:checked + span:after {
			background-color: #FF9800; }
	.switch.switch-warning.switch-warn input:checked + span {
		background-color: rgba(255, 152, 0, 0.5); }
	.switch.switch-success input:checked + span {
		background-color: #4CAF50; }
		.switch.switch-success input:checked + span:after {
			background-color: #4CAF50; }
	.switch.switch-success.switch-warn input:checked + span {
		background-color: rgba(76, 175, 80, 0.5); }
	.switch.switch-purple input:checked + span {
		background-color: #7E57C2; }
		.switch.switch-purple input:checked + span:after {
			background-color: #7E57C2; }
	.switch.switch-purple.switch-warn input:checked + span {
		background-color: rgba(126, 87, 194, 0.5); }

.switch input:checked + span {
	background-color: #1E88E5; }
	.switch input:checked + span:after {
		background-color: #1E88E5; }

.switch.switch-warn input:checked + span {
	background-color: rgba(30, 136, 229, 0.5); }

.switch input:checked + span:after {
	left: 50%; }

.switch input:disabled + span {
	background-color: rgba(158, 158, 158, 0.5) !important; }
	.switch input:disabled + span:after {
		background-color: #fff !important; }

/**
 * Google Material Colors (customization)
 */
.bg-pink-500 {
	background-color: #E91E63;
	color: #fff !important; }

.bg-purple-400 {
	background-color: #AB47BC;
	color: #fff !important; }

.bg-purple-500 {
	background-color: #9C27B0;
	color: #fff !important; }

.bg-deep-purple-500 {
	background-color: #673AB7;
	color: #fff !important; }

.bg-indigo-500 {
	background-color: #3F51B5;
	color: #fff !important; }

.bg-green-50 {
	background-color: #E8F5E9;
	color: #fff !important; }

.bg-green-500 {
	background-color: #4CAF50;
	color: #fff !important; }

.bg-blue-500 {
	background-color: #2196F3;
	color: #fff !important; }

.bg-blue-grey-900 {
	background-color: #263238;
	color: #fff !important; }

.bg-red-500 {
	background-color: #F44336;
	color: #fff !important; }

.bg-primary,
.bg-red-800, .bg-red-900,
.bg-pink-700, .bg-pink-800, .bg-pink-900,
.bg-purple, .bg-purple-500, .bg-purple-600, .bg-purple-700, .bg-purple-800, .bg-purple-900,
.bg-deep-purple, .bg-deep-purple-500, .bg-deep-purple-600, .bg-deep-purple-700, .bg-deep-purple-800, .bg-deep-purple-900, .bg-deep-purple-a400, .bg-deep-purple-a700,
.bg-indigo, .bg-indigo-500, .bg-indigo-600, .bg-indigo-700, .bg-indigo-800, .bg-indigo-900, .bg-indigo-a700, .bg-blue-800,
.bg-blue-900, .bg-light-blue-900,
.bg-cyan-900, .bg-teal-800, .bg-teal-900,
.bg-green-800, .bg-green-900,
.bg-brown, .bg-brown-500, .bg-brown-600, .bg-brown-700, .bg-brown-800, .bg-brown-900, .bg-blue-grey-600,
.bg-blue-grey-700, .bg-blue-grey-800, .bg-blue-grey-900, .bg-grey-600,
.bg-grey-700, .bg-grey-800, .bg-grey-900 {
	color: rgba(255, 255, 255, 0.97); }
	.bg-primary .text-muted,
	.bg-red-800 .text-muted, .bg-red-900 .text-muted,
	.bg-pink-700 .text-muted, .bg-pink-800 .text-muted, .bg-pink-900 .text-muted,
	.bg-purple .text-muted, .bg-purple-500 .text-muted, .bg-purple-600 .text-muted, .bg-purple-700 .text-muted, .bg-purple-800 .text-muted, .bg-purple-900 .text-muted,
	.bg-deep-purple .text-muted, .bg-deep-purple-500 .text-muted, .bg-deep-purple-600 .text-muted, .bg-deep-purple-700 .text-muted, .bg-deep-purple-800 .text-muted, .bg-deep-purple-900 .text-muted, .bg-deep-purple-a400 .text-muted, .bg-deep-purple-a700 .text-muted,
	.bg-indigo .text-muted, .bg-indigo-500 .text-muted, .bg-indigo-600 .text-muted, .bg-indigo-700 .text-muted, .bg-indigo-800 .text-muted, .bg-indigo-900 .text-muted, .bg-indigo-a700 .text-muted, .bg-blue-800 .text-muted,
	.bg-blue-900 .text-muted, .bg-light-blue-900 .text-muted,
	.bg-cyan-900 .text-muted, .bg-teal-800 .text-muted, .bg-teal-900 .text-muted,
	.bg-green-800 .text-muted, .bg-green-900 .text-muted,
	.bg-brown .text-muted, .bg-brown-500 .text-muted, .bg-brown-600 .text-muted, .bg-brown-700 .text-muted, .bg-brown-800 .text-muted, .bg-brown-900 .text-muted, .bg-blue-grey-600 .text-muted,
	.bg-blue-grey-700 .text-muted, .bg-blue-grey-800 .text-muted, .bg-blue-grey-900 .text-muted, .bg-grey-600 .text-muted,
	.bg-grey-700 .text-muted, .bg-grey-800 .text-muted, .bg-grey-900 .text-muted {
		color: rgba(255, 255, 255, 0.57) !important; }
	.bg-primary .text-soft,
	.bg-red-800 .text-soft, .bg-red-900 .text-soft,
	.bg-pink-700 .text-soft, .bg-pink-800 .text-soft, .bg-pink-900 .text-soft,
	.bg-purple .text-soft, .bg-purple-500 .text-soft, .bg-purple-600 .text-soft, .bg-purple-700 .text-soft, .bg-purple-800 .text-soft, .bg-purple-900 .text-soft,
	.bg-deep-purple .text-soft, .bg-deep-purple-500 .text-soft, .bg-deep-purple-600 .text-soft, .bg-deep-purple-700 .text-soft, .bg-deep-purple-800 .text-soft, .bg-deep-purple-900 .text-soft, .bg-deep-purple-a400 .text-soft, .bg-deep-purple-a700 .text-soft,
	.bg-indigo .text-soft, .bg-indigo-500 .text-soft, .bg-indigo-600 .text-soft, .bg-indigo-700 .text-soft, .bg-indigo-800 .text-soft, .bg-indigo-900 .text-soft, .bg-indigo-a700 .text-soft, .bg-blue-800 .text-soft,
	.bg-blue-900 .text-soft, .bg-light-blue-900 .text-soft,
	.bg-cyan-900 .text-soft, .bg-teal-800 .text-soft, .bg-teal-900 .text-soft,
	.bg-green-800 .text-soft, .bg-green-900 .text-soft,
	.bg-brown .text-soft, .bg-brown-500 .text-soft, .bg-brown-600 .text-soft, .bg-brown-700 .text-soft, .bg-brown-800 .text-soft, .bg-brown-900 .text-soft, .bg-blue-grey-600 .text-soft,
	.bg-blue-grey-700 .text-soft, .bg-blue-grey-800 .text-soft, .bg-blue-grey-900 .text-soft, .bg-grey-600 .text-soft,
	.bg-grey-700 .text-soft, .bg-grey-800 .text-soft, .bg-grey-900 .text-soft {
		color: rgba(255, 255, 255, 0.26); }

.bg-red, .bg-red-500, .bg-red-600, .bg-red-700, .bg-red-a200, .bg-red-a400, .bg-red-a700, .bg-pink,
.bg-pink-500, .bg-pink-600, .bg-pink-a200, .bg-pink-a400, .bg-pink-a700, .bg-purple-300,
.bg-purple-400, .bg-purple-a200, .bg-purple-a400, .bg-purple-a700, .bg-deep-purple-300,
.bg-deep-purple-400, .bg-deep-purple-a200,
.bg-indigo-300, .bg-indigo-400, .bg-indigo-a200, .bg-indigo-a400, .bg-blue,
.bg-blue-500, .bg-blue-600, .bg-blue-700, .bg-blue-a200, .bg-blue-a400, .bg-blue-a700, .bg-light-blue,
.bg-light-blue-500, .bg-light-blue-600, .bg-light-blue-700, .bg-light-blue-800, .bg-light-blue-a700, .bg-cyan,
.bg-cyan-500, .bg-cyan-600, .bg-cyan-700, .bg-cyan-800, .bg-teal,
.bg-teal-500, .bg-teal-600, .bg-teal-700,
.bg-amber-900, .bg-amber-800, .bg-yellow-900,
.bg-green, .bg-green-500, .bg-green-600, .bg-green-700, .bg-light-green-800, .bg-light-green-900,
.bg-lime-900, .bg-orange-500, .bg-orange-600, .bg-orange-700, .bg-orange-800, .bg-orange-900, .bg-orange-a400, .bg-orange-a700,
.bg-deep-orange, .bg-deep-orange-500, .bg-deep-orange-600, .bg-deep-orange-700, .bg-deep-orange-800, .bg-deep-orange-900, .bg-deep-orange-a400, .bg-deep-orange-a700, .bg-brown-300,
.bg-brown-400, .bg-blue-grey, .bg-blue-grey-400, .bg-blue-grey-500 {
	color: #fff; }
	.bg-red .text-muted, .bg-red-500 .text-muted, .bg-red-600 .text-muted, .bg-red-700 .text-muted, .bg-red-a200 .text-muted, .bg-red-a400 .text-muted, .bg-red-a700 .text-muted, .bg-pink .text-muted,
	.bg-pink-500 .text-muted, .bg-pink-600 .text-muted, .bg-pink-a200 .text-muted, .bg-pink-a400 .text-muted, .bg-pink-a700 .text-muted, .bg-purple-300 .text-muted,
	.bg-purple-400 .text-muted, .bg-purple-a200 .text-muted, .bg-purple-a400 .text-muted, .bg-purple-a700 .text-muted, .bg-deep-purple-300 .text-muted,
	.bg-deep-purple-400 .text-muted, .bg-deep-purple-a200 .text-muted,
	.bg-indigo-300 .text-muted, .bg-indigo-400 .text-muted, .bg-indigo-a200 .text-muted, .bg-indigo-a400 .text-muted, .bg-blue .text-muted,
	.bg-blue-500 .text-muted, .bg-blue-600 .text-muted, .bg-blue-700 .text-muted, .bg-blue-a200 .text-muted, .bg-blue-a400 .text-muted, .bg-blue-a700 .text-muted, .bg-light-blue .text-muted,
	.bg-light-blue-500 .text-muted, .bg-light-blue-600 .text-muted, .bg-light-blue-700 .text-muted, .bg-light-blue-800 .text-muted, .bg-light-blue-a700 .text-muted, .bg-cyan .text-muted,
	.bg-cyan-500 .text-muted, .bg-cyan-600 .text-muted, .bg-cyan-700 .text-muted, .bg-cyan-800 .text-muted, .bg-teal .text-muted,
	.bg-teal-500 .text-muted, .bg-teal-600 .text-muted, .bg-teal-700 .text-muted,
	.bg-amber-900 .text-muted, .bg-amber-800 .text-muted, .bg-yellow-900 .text-muted,
	.bg-green .text-muted, .bg-green-500 .text-muted, .bg-green-600 .text-muted, .bg-green-700 .text-muted, .bg-light-green-800 .text-muted, .bg-light-green-900 .text-muted,
	.bg-lime-900 .text-muted, .bg-orange-500 .text-muted, .bg-orange-600 .text-muted, .bg-orange-700 .text-muted, .bg-orange-800 .text-muted, .bg-orange-900 .text-muted, .bg-orange-a400 .text-muted, .bg-orange-a700 .text-muted,
	.bg-deep-orange .text-muted, .bg-deep-orange-500 .text-muted, .bg-deep-orange-600 .text-muted, .bg-deep-orange-700 .text-muted, .bg-deep-orange-800 .text-muted, .bg-deep-orange-900 .text-muted, .bg-deep-orange-a400 .text-muted, .bg-deep-orange-a700 .text-muted, .bg-brown-300 .text-muted,
	.bg-brown-400 .text-muted, .bg-blue-grey .text-muted, .bg-blue-grey-400 .text-muted, .bg-blue-grey-500 .text-muted {
		color: rgba(255, 255, 255, 0.57) !important; }
	.bg-red .text-soft, .bg-red-500 .text-soft, .bg-red-600 .text-soft, .bg-red-700 .text-soft, .bg-red-a200 .text-soft, .bg-red-a400 .text-soft, .bg-red-a700 .text-soft, .bg-pink .text-soft,
	.bg-pink-500 .text-soft, .bg-pink-600 .text-soft, .bg-pink-a200 .text-soft, .bg-pink-a400 .text-soft, .bg-pink-a700 .text-soft, .bg-purple-300 .text-soft,
	.bg-purple-400 .text-soft, .bg-purple-a200 .text-soft, .bg-purple-a400 .text-soft, .bg-purple-a700 .text-soft, .bg-deep-purple-300 .text-soft,
	.bg-deep-purple-400 .text-soft, .bg-deep-purple-a200 .text-soft,
	.bg-indigo-300 .text-soft, .bg-indigo-400 .text-soft, .bg-indigo-a200 .text-soft, .bg-indigo-a400 .text-soft, .bg-blue .text-soft,
	.bg-blue-500 .text-soft, .bg-blue-600 .text-soft, .bg-blue-700 .text-soft, .bg-blue-a200 .text-soft, .bg-blue-a400 .text-soft, .bg-blue-a700 .text-soft, .bg-light-blue .text-soft,
	.bg-light-blue-500 .text-soft, .bg-light-blue-600 .text-soft, .bg-light-blue-700 .text-soft, .bg-light-blue-800 .text-soft, .bg-light-blue-a700 .text-soft, .bg-cyan .text-soft,
	.bg-cyan-500 .text-soft, .bg-cyan-600 .text-soft, .bg-cyan-700 .text-soft, .bg-cyan-800 .text-soft, .bg-teal .text-soft,
	.bg-teal-500 .text-soft, .bg-teal-600 .text-soft, .bg-teal-700 .text-soft,
	.bg-amber-900 .text-soft, .bg-amber-800 .text-soft, .bg-yellow-900 .text-soft,
	.bg-green .text-soft, .bg-green-500 .text-soft, .bg-green-600 .text-soft, .bg-green-700 .text-soft, .bg-light-green-800 .text-soft, .bg-light-green-900 .text-soft,
	.bg-lime-900 .text-soft, .bg-orange-500 .text-soft, .bg-orange-600 .text-soft, .bg-orange-700 .text-soft, .bg-orange-800 .text-soft, .bg-orange-900 .text-soft, .bg-orange-a400 .text-soft, .bg-orange-a700 .text-soft,
	.bg-deep-orange .text-soft, .bg-deep-orange-500 .text-soft, .bg-deep-orange-600 .text-soft, .bg-deep-orange-700 .text-soft, .bg-deep-orange-800 .text-soft, .bg-deep-orange-900 .text-soft, .bg-deep-orange-a400 .text-soft, .bg-deep-orange-a700 .text-soft, .bg-brown-300 .text-soft,
	.bg-brown-400 .text-soft, .bg-blue-grey .text-soft, .bg-blue-grey-400 .text-soft, .bg-blue-grey-500 .text-soft {
		color: rgba(255, 255, 255, 0.26); }

/**
 * Layout Variants
 */
.sidebar-cover .sidebar-container {
	transform: translate3d(-100%, 0, 0); }
	.sidebar-cover .sidebar-container .brand-header {
		box-shadow: 0 0 0 #000; }

.sidebar-cover .header-container {
	z-index: 30; }

.sidebar-cover .main-container,
.sidebar-cover .footer-container,
.sidebar-cover .header-container {
	margin-left: 0 !important; }

.sidebar-cover.sidebar-cover-visible .sidebar-container {
	transform: translate3d(0, 0, 0); }

.no-csstransforms3d .sidebar-cover .sidebar-cover .sidebar-container {
	margin-left: -240px; }

.no-csstransforms3d .sidebar-cover .sidebar-cover.sidebar-cover-visible .sidebar-container {
	margin-left: 0; }

/**
 * Main Layout
 */
.layout-container {
	position: relative;
	width: 100%;
	height: 100%; }

.main-container {
	position: relative;
	height: calc(100% - 60px);
	overflow: auto;
	-webkit-overflow-scrolling: touch; }
	@media only screen and (min-width: 992px) {
		.main-container {
			margin-left: 240px; } }
	.main-container .section-container {
		min-height: calc(100% - 60px); }

.content-heading {
	padding: 1.2rem;
	box-shadow: 0 1px 0 rgba(20, 20, 20, 0.075); }

/**
 * Dashboard
 */
.welcome {
	padding-top: 1rem; }
	@media only screen and (min-width: 768px) {
		.welcome {
			padding-top: 0; } }
	.welcome > h1 {
		font-size: 2rem;
		line-height: 1;
		margin: 0 0 .5rem 0; }
		@media only screen and (min-width: 768px) {
			.welcome > h1 {
				font-size: 2.5rem; } }
	.welcome > small {
		color: #a2a2a2; }

/**
 * Nestable
 */
.dd {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	max-width: 600px;
	list-style: none;
	font-size: 13px;
	line-height: 20px; }

.dd-list {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none; }
	.dd-list .dd-list {
		padding-left: 30px; }

.dd-collapsed .dd-list {
	display: none; }

.dd-item,
.dd-empty,
.dd-placeholder {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	min-height: 20px;
	font-size: 13px;
	line-height: 20px; }

.dd-handle {
	display: block;
	margin: 5px 0;
	padding: 5px 0;
	text-decoration: none;
	color: inherit;
	border-radius: 3px; }

.dd-placeholder,
.dd-empty {
	margin: 5px 0;
	padding: 0;
	min-height: 30px;
	border: 1px dashed #b6bcbf;
	box-sizing: border-box; }

.dd-empty {
	border: 1px dashed #bbb;
	min-height: 100px;
	background-color: #e5e5e5;
	background-size: 60px 60px;
	background-position: 0 0, 30px 30px; }

.dd-dragel {
	position: absolute;
	pointer-events: none;
	z-index: 9999; }
	.dd-dragel > .dd-item .dd-handle {
		margin-top: 0; }

.dd-item > button {
	display: none !important;
	position: absolute;
	display: block;
	right: 5px;
	top: 5px;
	width: 25px;
	height: 30px;
	padding: 0;
	text-indent: 100%;
	overflow: hidden;
	border: 0;
	background: transparent;
	z-index: 10; }
	.dd-item > button:before {
		content: '+';
		position: absolute;
		display: block;
		width: 100%;
		text-indent: 0;
		font-weight: bold;
		color: #a2a2a2;
		font-size: 2rem; }

.dd-item > button[data-action="collapse"]:before {
	content: '-'; }

/**
 * Toaster
 */
.toast {
	background-color: #ECEFF1; }

.toast-success {
	background-color: #4CAF50; }

.toast-error {
	background-color: #FF5252; }

.toast-info {
	background-color: #03A9F4; }

.toast-warning {
	background-color: #FF9800; }

/**
 * Footer
 */
.footer-container {
	left: 0;
	right: 0;
	bottom: 0;
	height: 60px;
	border-top: 1px solid rgba(90, 90, 90, 0.12);
	background-color: #f6fbfe;
	box-shadow: 0px -5px 25px 0px rgba(123, 123, 123, 0.15);
	color: #90A4AE; }

.footer-icon {
	display: block;
	padding: 10px 6px;
	border-bottom: 2px solid transparent;
	font-size: 2rem;
	text-align: center;
	height: 100%;
	line-height: 1;
	text-decoration: none !important;
	flex-basis: 0;
	flex-grow: 1;
	color: inherit; }
	.footer-icon:hover, .footer-icon:focus, .footer-icon:active, .footer-icon.active {
		color: #03A9F4 !important;
		border-bottom-color: #03A9F4; }
	.footer-icon span {
		display: block;
		font-size: .7rem; }
	.footer-icon.footer-icon-lg em {
		display: inline-block;
		font-size: 2.75rem;
		margin-top: -.95rem; }

.footer-fixed .footer-container {
	position: fixed; }
	@media only screen and (min-width: 992px) {
		.footer-fixed .footer-container {
			margin-left: 240px; } }

.footer-fixed .main-container {
	padding-bottom: 60px; }

/**
 * Dropzone
 */
.dropzone {
	border: 0; }
	.dropzone .dz-default.dz-message {
		font-size: 35px; }
	.dropzone .dz-preview.dz-image-preview {
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }
		.dropzone .dz-preview.dz-image-preview .dz-image {
			border-radius: 1px; }
		.dropzone .dz-preview.dz-image-preview .dz-details {
			position: relative;
			line-height: 1;
			padding: 6px 4px;
			margin: 0;
			opacity: 1;
			font-size: 14px; }
	.dropzone .dz-preview .dz-image img {
		transition: transform .2s ease; }
	.dropzone .dz-preview:hover .dz-image img {
		-webkit-filter: none;
		filter: none;
		transform: scale(2);
		transition: transform .5s ease; }
	.dropzone .dz-preview.dz-file-preview .dz-image {
		border-radius: 1px;
		background-image: none;
		background-color: #ECEFF1; }
	.dropzone .dz-filename {
		display: none; }
	.dropzone .dz-preview .dz-remove {
		color: #a2a2a2;
		font-size: 12px; }
		.dropzone .dz-preview .dz-remove:hover {
			color: #FF5252;
			text-decoration: none; }
	.dropzone .dz-preview .dz-error-message {
		top: 130px;
		border-radius: 2px;
		transition: all .2s ease; }
		.dropzone .dz-preview .dz-error-message:after {
			display: none; }
	.dropzone .dz-preview.dz-error:hover .dz-error-message {
		top: 100px; }

/**
 * Forms - Advanced plugins
 */
.noUi-handle {
	border-radius: 50%;
	border: 0;
	box-shadow: 0 0 0 transparent;
	background-color: #1E88E5; }

.noUi-horizontal .noUi-handle {
	width: 24px;
	height: 24px;
	top: -10px; }
	.noUi-horizontal .noUi-handle:before, .noUi-horizontal .noUi-handle:after {
		display: none; }

.noUi-connect {
	background-color: #1E88E5; }

.noUi-horizontal {
	height: 4px;
	border: 0;
	background-color: rgba(160, 160, 160, 0.2);
	box-shadow: 0 0 0 #000; }

.noUi-target.noUi-connect {
	background-color: #1E88E5;
	box-shadow: 0 0 0 transparent; }

.ui-slider-success .noUi-handle {
	background-color: #4CAF50; }

.ui-slider-success .noUi-connect {
	background-color: #4CAF50; }

.ui-slider-success.noUi-target.noUi-connect {
	background-color: #4CAF50; }

.ui-slider-info .noUi-handle {
	background-color: #03A9F4; }

.ui-slider-info .noUi-connect {
	background-color: #03A9F4; }

.ui-slider-info.noUi-target.noUi-connect {
	background-color: #03A9F4; }

.ui-slider-warning .noUi-handle {
	background-color: #FF9800; }

.ui-slider-warning .noUi-connect {
	background-color: #FF9800; }

.ui-slider-warning.noUi-target.noUi-connect {
	background-color: #FF9800; }

.ui-slider-danger .noUi-handle {
	background-color: #FF5252; }

.ui-slider-danger .noUi-connect {
	background-color: #FF5252; }

.ui-slider-danger.noUi-target.noUi-connect {
	background-color: #FF5252; }

/* Select2
	 ----------------------------------- */
.select2-container {
	max-width: 100% !important; }

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
	position: relative;
	background-color: transparent;
	color: inherit;
	border: 1px solid rgba(0, 0, 0, 0.14);
	min-height: 32px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: inherit; }

.select2-container--open.select2-container--default .select2-selection--single,
.select2-container--open.select2-container--default .select2-selection--multiple,
.select2-container--focus.select2-container--default .select2-selection--single,
.select2-container--focus.select2-container--default .select2-selection--multiple {
	border-color: #90c4f2; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	border-radius: 5px;
	border: 0;
	background-color: rgba(162, 162, 162, 0.12);
	padding: 2px 8px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: inherit; }

.select2-dropdown {
	top: 1px;
	transform-origin: top left;
	animation: zoomIn .2s ease; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #E0E0E0;
	color: #263238; }

.select2-container--default .select2-dropdown .select2-results__option[aria-selected=true] {
	background-color: #1E88E5;
	color: #fff; }

/* Clockpicker
	 ----------------------------------- */
.clockpicker-popover {
	transform-origin: top left;
	animation: zoomIn .2s ease; }
	.clockpicker-popover .arrow {
		display: none; }
	.clockpicker-popover.left {
		transform-origin: top right; }
	.clockpicker-popover.top {
		transform-origin: bottom left; }
	.clockpicker-popover.popover.bottom {
		margin-top: 1px; }

/* Colorpicker
	 -----------------------------------*/
.colorpicker {
	transform-origin: top right;
	animation: zoomIn .2s ease; }
	.colorpicker:before, .colorpicker:after {
		display: none; }

.colorpicker-2x .colorpicker-saturation {
	width: 200px;
	height: 200px; }

.colorpicker-2x .colorpicker-hue,
.colorpicker-2x .colorpicker-alpha {
	width: 30px;
	height: 200px; }

.colorpicker-2x .colorpicker-color,
.colorpicker-2x .colorpicker-color div {
	height: 30px; }

/* Multiselect
	 -----------------------------------*/
.ms-container .ms-list {
	border-color: rgba(160, 160, 160, 0.3); }
	.ms-container .ms-list.ms-focus {
		box-shadow: 0 0 0 #000;
		border-color: #1E88E5; }

.ms-container .ms-selectable li.ms-elem-selectable {
	border-color: rgba(160, 160, 160, 0.3); }

.ms-container .ms-selectable li.ms-hover {
	background-color: #1E88E5;
	color: #fff; }

.ms-container .ms-selectable li.disabled {
	color: #aaa; }

.ms-container .ms-selection li.ms-elem-selection {
	border-color: rgba(160, 160, 160, 0.3); }

.ms-container .ms-selection li.ms-hover {
	background-color: #1E88E5;
	color: #fff; }

.ms-container .ms-selection li.disabled {
	color: #aaa; }

/* Datepicker
	 -----------------------------------*/
.ui-datepicker-responsive {
	max-width: 100%;
	overflow: auto; }

.ui-datepicker {
	background-color: transparent;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }
	.ui-datepicker.ui-datepicker-popup {
		box-shadow: 0 0 0 #000; }
	.ui-datepicker .datepicker-inline {
		display: inline-block;
		padding: 0;
		width: 100%; }
	.ui-datepicker .datepicker table {
		width: 100%; }
	.ui-datepicker .datepicker table tr td,
	.ui-datepicker .datepicker table tr th {
		border-radius: 0;
		padding: 16px 12px;
		transition: all .2s ease;
		font-size: 12px; }
	.ui-datepicker .datepicker tbody tr td {
		padding: 12px 12px; }
		.ui-datepicker .datepicker tbody tr td.selected, .ui-datepicker .datepicker tbody tr td.selected:hover, .ui-datepicker .datepicker tbody tr td.selected.disabled, .ui-datepicker .datepicker tbody tr td.selected.disabled:hover {
			background-image: none;
			background-color: #ECEFF1;
			color: #555; }
	.ui-datepicker .datepicker table tr td.day:hover {
		background: rgba(162, 162, 162, 0.12); }
	.ui-datepicker .datepicker table thead tr:last-child th {
		padding: 0;
		line-height: 36px;
		font-size: 85%; }
	.ui-datepicker .datepicker thead tr:first-child th:hover, .ui-datepicker .datepicker tfoot tr th:hover {
		background-image: none;
		background-color: #E91E63;
		color: #fff; }
	.ui-datepicker .datepicker table tr td.active:hover, .ui-datepicker .datepicker table tr td.active:hover:hover, .ui-datepicker .datepicker table tr td.active.disabled:hover, .ui-datepicker .datepicker table tr td.active.disabled:hover:hover, .ui-datepicker .datepicker table tr td.active:focus, .ui-datepicker .datepicker table tr td.active:hover:focus, .ui-datepicker .datepicker table tr td.active.disabled:focus, .ui-datepicker .datepicker table tr td.active.disabled:hover:focus, .ui-datepicker .datepicker table tr td.active:active, .ui-datepicker .datepicker table tr td.active:hover:active, .ui-datepicker .datepicker table tr td.active.disabled:active, .ui-datepicker .datepicker table tr td.active.disabled:hover:active, .ui-datepicker .datepicker table tr td.active.active, .ui-datepicker .datepicker table tr td.active:hover.active, .ui-datepicker .datepicker table tr td.active.disabled.active, .ui-datepicker .datepicker table tr td.active.disabled:hover.active, .ui-datepicker .open .dropdown-toggle.datepicker table tr td.active, .ui-datepicker .open .dropdown-toggle.datepicker table tr td.active:hover, .ui-datepicker .open .dropdown-toggle.datepicker table tr td.active.disabled, .ui-datepicker .open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
		background-image: none;
		background-color: #E91E63;
		color: #fff;
		border-radius: 4px; }
	.ui-datepicker .datepicker table tr td span.active:hover, .ui-datepicker .datepicker table tr td span.active:hover:hover, .ui-datepicker .datepicker table tr td span.active.disabled:hover, .ui-datepicker .datepicker table tr td span.active.disabled:hover:hover, .ui-datepicker .datepicker table tr td span.active:focus, .ui-datepicker .datepicker table tr td span.active:hover:focus, .ui-datepicker .datepicker table tr td span.active.disabled:focus, .ui-datepicker .datepicker table tr td span.active.disabled:hover:focus, .ui-datepicker .datepicker table tr td span.active:active, .ui-datepicker .datepicker table tr td span.active:hover:active, .ui-datepicker .datepicker table tr td span.active.disabled:active, .ui-datepicker .datepicker table tr td span.active.disabled:hover:active, .ui-datepicker .datepicker table tr td span.active.active, .ui-datepicker .datepicker table tr td span.active:hover.active, .ui-datepicker .datepicker table tr td span.active.disabled.active, .ui-datepicker .datepicker table tr td span.active.disabled:hover.active, .ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active, .ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active:hover, .ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active.disabled, .ui-datepicker .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover,
	.ui-datepicker .datepicker .datepicker-switch:hover, .ui-datepicker .datepicker .prev:hover, .ui-datepicker .datepicker .next:hover, .ui-datepicker .datepicker tfoot tr th:hover {
		background-image: none;
		background-color: #E91E63;
		color: #fff; }
	.ui-datepicker .input-daterange .input-group-addon {
		background: transparent;
		border: 0 !important;
		vertical-align: baseline; }

.datepicker.dropdown-menu {
	margin: 0;
	padding: 0 0 5px 0;
	border: 0;
	animation: zoomIn .2s ease; }
	.datepicker.dropdown-menu.datepicker-orient-top {
		transform-origin: top left; }
	.datepicker.dropdown-menu.datepicker-orient-bottom {
		transform-origin: bottom left; }
	.datepicker.dropdown-menu table tr td, .datepicker.dropdown-menu table tr th {
		border-radius: 0; }
	.datepicker.dropdown-menu:after, .datepicker.dropdown-menu:before {
		display: none; }

.dp-theme-primary .datepicker table thead tr:first-child th {
	background-color: #1E88E5;
	color: #fff; }

.dp-theme-primary .datepicker table thead tr:last-child th {
	background-color: #187bd1;
	color: #fff; }

.dp-theme-primary .datepicker thead tr:first-child th:hover, .dp-theme-primary .datepicker tfoot tr th:hover {
	background-color: #1E88E5;
	color: #fff; }

.dp-theme-primary .datepicker table tr td.active:hover, .dp-theme-primary .datepicker table tr td.active:hover:hover, .dp-theme-primary .datepicker table tr td.active.disabled:hover, .dp-theme-primary .datepicker table tr td.active.disabled:hover:hover, .dp-theme-primary .datepicker table tr td.active:focus, .dp-theme-primary .datepicker table tr td.active:hover:focus, .dp-theme-primary .datepicker table tr td.active.disabled:focus, .dp-theme-primary .datepicker table tr td.active.disabled:hover:focus, .dp-theme-primary .datepicker table tr td.active:active, .dp-theme-primary .datepicker table tr td.active:hover:active, .dp-theme-primary .datepicker table tr td.active.disabled:active, .dp-theme-primary .datepicker table tr td.active.disabled:hover:active, .dp-theme-primary .datepicker table tr td.active.active, .dp-theme-primary .datepicker table tr td.active:hover.active, .dp-theme-primary .datepicker table tr td.active.disabled.active, .dp-theme-primary .datepicker table tr td.active.disabled:hover.active, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active:hover, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active.disabled, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-primary .datepicker .datepicker-switch:hover, .dp-theme-primary .datepicker .prev:hover, .dp-theme-primary .datepicker .next:hover, .dp-theme-primary .datepicker tfoot tr th:hover {
	background-color: #1E88E5;
	color: #fff; }

.dp-theme-primary.datepicker-standalone .datepicker .datepicker-switch, .dp-theme-primary.datepicker-standalone .datepicker .prev, .dp-theme-primary.datepicker-standalone .datepicker .next, .dp-theme-primary.datepicker-standalone .datepicker tfoot tr th {
	background-color: #1E88E5;
	color: #fff; }

.dp-theme-primary .datepicker table tr td span.active:hover, .dp-theme-primary .datepicker table tr td span.active:hover:hover, .dp-theme-primary .datepicker table tr td span.active.disabled:hover, .dp-theme-primary .datepicker table tr td span.active.disabled:hover:hover, .dp-theme-primary .datepicker table tr td span.active:focus, .dp-theme-primary .datepicker table tr td span.active:hover:focus, .dp-theme-primary .datepicker table tr td span.active.disabled:focus, .dp-theme-primary .datepicker table tr td span.active.disabled:hover:focus, .dp-theme-primary .datepicker table tr td span.active:active, .dp-theme-primary .datepicker table tr td span.active:hover:active, .dp-theme-primary .datepicker table tr td span.active.disabled:active, .dp-theme-primary .datepicker table tr td span.active.disabled:hover:active, .dp-theme-primary .datepicker table tr td span.active.active, .dp-theme-primary .datepicker table tr td span.active:hover.active, .dp-theme-primary .datepicker table tr td span.active.disabled.active, .dp-theme-primary .datepicker table tr td span.active.disabled:hover.active, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active:hover, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active.disabled, .dp-theme-primary .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #1E88E5;
	color: #fff; }

.dp-theme-primary .datepicker tbody tr td {
	padding: 12px 12px; }
	.dp-theme-primary .datepicker tbody tr td.selected, .dp-theme-primary .datepicker tbody tr td.selected:hover, .dp-theme-primary .datepicker tbody tr td.selected.disabled, .dp-theme-primary .datepicker tbody tr td.selected.disabled:hover {
		color: #1E88E5; }

.dp-theme-success .datepicker table thead tr:first-child th {
	background-color: #4CAF50;
	color: #fff; }

.dp-theme-success .datepicker table thead tr:last-child th {
	background-color: #449d48;
	color: #fff; }

.dp-theme-success .datepicker thead tr:first-child th:hover, .dp-theme-success .datepicker tfoot tr th:hover {
	background-color: #4CAF50;
	color: #fff; }

.dp-theme-success .datepicker table tr td.active:hover, .dp-theme-success .datepicker table tr td.active:hover:hover, .dp-theme-success .datepicker table tr td.active.disabled:hover, .dp-theme-success .datepicker table tr td.active.disabled:hover:hover, .dp-theme-success .datepicker table tr td.active:focus, .dp-theme-success .datepicker table tr td.active:hover:focus, .dp-theme-success .datepicker table tr td.active.disabled:focus, .dp-theme-success .datepicker table tr td.active.disabled:hover:focus, .dp-theme-success .datepicker table tr td.active:active, .dp-theme-success .datepicker table tr td.active:hover:active, .dp-theme-success .datepicker table tr td.active.disabled:active, .dp-theme-success .datepicker table tr td.active.disabled:hover:active, .dp-theme-success .datepicker table tr td.active.active, .dp-theme-success .datepicker table tr td.active:hover.active, .dp-theme-success .datepicker table tr td.active.disabled.active, .dp-theme-success .datepicker table tr td.active.disabled:hover.active, .dp-theme-success .open .dropdown-toggle.datepicker table tr td.active, .dp-theme-success .open .dropdown-toggle.datepicker table tr td.active:hover, .dp-theme-success .open .dropdown-toggle.datepicker table tr td.active.disabled, .dp-theme-success .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-success .datepicker .datepicker-switch:hover, .dp-theme-success .datepicker .prev:hover, .dp-theme-success .datepicker .next:hover, .dp-theme-success .datepicker tfoot tr th:hover {
	background-color: #4CAF50;
	color: #fff; }

.dp-theme-success.datepicker-standalone .datepicker .datepicker-switch, .dp-theme-success.datepicker-standalone .datepicker .prev, .dp-theme-success.datepicker-standalone .datepicker .next, .dp-theme-success.datepicker-standalone .datepicker tfoot tr th {
	background-color: #4CAF50;
	color: #fff; }

.dp-theme-success .datepicker table tr td span.active:hover, .dp-theme-success .datepicker table tr td span.active:hover:hover, .dp-theme-success .datepicker table tr td span.active.disabled:hover, .dp-theme-success .datepicker table tr td span.active.disabled:hover:hover, .dp-theme-success .datepicker table tr td span.active:focus, .dp-theme-success .datepicker table tr td span.active:hover:focus, .dp-theme-success .datepicker table tr td span.active.disabled:focus, .dp-theme-success .datepicker table tr td span.active.disabled:hover:focus, .dp-theme-success .datepicker table tr td span.active:active, .dp-theme-success .datepicker table tr td span.active:hover:active, .dp-theme-success .datepicker table tr td span.active.disabled:active, .dp-theme-success .datepicker table tr td span.active.disabled:hover:active, .dp-theme-success .datepicker table tr td span.active.active, .dp-theme-success .datepicker table tr td span.active:hover.active, .dp-theme-success .datepicker table tr td span.active.disabled.active, .dp-theme-success .datepicker table tr td span.active.disabled:hover.active, .dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active, .dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active:hover, .dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active.disabled, .dp-theme-success .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #4CAF50;
	color: #fff; }

.dp-theme-success .datepicker tbody tr td {
	padding: 12px 12px; }
	.dp-theme-success .datepicker tbody tr td.selected, .dp-theme-success .datepicker tbody tr td.selected:hover, .dp-theme-success .datepicker tbody tr td.selected.disabled, .dp-theme-success .datepicker tbody tr td.selected.disabled:hover {
		color: #4CAF50; }

.dp-theme-warning .datepicker table thead tr:first-child th {
	background-color: #FF9800;
	color: #fff; }

.dp-theme-warning .datepicker table thead tr:last-child th {
	background-color: #e68900;
	color: #fff; }

.dp-theme-warning .datepicker thead tr:first-child th:hover, .dp-theme-warning .datepicker tfoot tr th:hover {
	background-color: #FF9800;
	color: #fff; }

.dp-theme-warning .datepicker table tr td.active:hover, .dp-theme-warning .datepicker table tr td.active:hover:hover, .dp-theme-warning .datepicker table tr td.active.disabled:hover, .dp-theme-warning .datepicker table tr td.active.disabled:hover:hover, .dp-theme-warning .datepicker table tr td.active:focus, .dp-theme-warning .datepicker table tr td.active:hover:focus, .dp-theme-warning .datepicker table tr td.active.disabled:focus, .dp-theme-warning .datepicker table tr td.active.disabled:hover:focus, .dp-theme-warning .datepicker table tr td.active:active, .dp-theme-warning .datepicker table tr td.active:hover:active, .dp-theme-warning .datepicker table tr td.active.disabled:active, .dp-theme-warning .datepicker table tr td.active.disabled:hover:active, .dp-theme-warning .datepicker table tr td.active.active, .dp-theme-warning .datepicker table tr td.active:hover.active, .dp-theme-warning .datepicker table tr td.active.disabled.active, .dp-theme-warning .datepicker table tr td.active.disabled:hover.active, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active:hover, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active.disabled, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-warning .datepicker .datepicker-switch:hover, .dp-theme-warning .datepicker .prev:hover, .dp-theme-warning .datepicker .next:hover, .dp-theme-warning .datepicker tfoot tr th:hover {
	background-color: #FF9800;
	color: #fff; }

.dp-theme-warning.datepicker-standalone .datepicker .datepicker-switch, .dp-theme-warning.datepicker-standalone .datepicker .prev, .dp-theme-warning.datepicker-standalone .datepicker .next, .dp-theme-warning.datepicker-standalone .datepicker tfoot tr th {
	background-color: #FF9800;
	color: #fff; }

.dp-theme-warning .datepicker table tr td span.active:hover, .dp-theme-warning .datepicker table tr td span.active:hover:hover, .dp-theme-warning .datepicker table tr td span.active.disabled:hover, .dp-theme-warning .datepicker table tr td span.active.disabled:hover:hover, .dp-theme-warning .datepicker table tr td span.active:focus, .dp-theme-warning .datepicker table tr td span.active:hover:focus, .dp-theme-warning .datepicker table tr td span.active.disabled:focus, .dp-theme-warning .datepicker table tr td span.active.disabled:hover:focus, .dp-theme-warning .datepicker table tr td span.active:active, .dp-theme-warning .datepicker table tr td span.active:hover:active, .dp-theme-warning .datepicker table tr td span.active.disabled:active, .dp-theme-warning .datepicker table tr td span.active.disabled:hover:active, .dp-theme-warning .datepicker table tr td span.active.active, .dp-theme-warning .datepicker table tr td span.active:hover.active, .dp-theme-warning .datepicker table tr td span.active.disabled.active, .dp-theme-warning .datepicker table tr td span.active.disabled:hover.active, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active:hover, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active.disabled, .dp-theme-warning .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #FF9800;
	color: #fff; }

.dp-theme-warning .datepicker tbody tr td {
	padding: 12px 12px; }
	.dp-theme-warning .datepicker tbody tr td.selected, .dp-theme-warning .datepicker tbody tr td.selected:hover, .dp-theme-warning .datepicker tbody tr td.selected.disabled, .dp-theme-warning .datepicker tbody tr td.selected.disabled:hover {
		color: #FF9800; }

.dp-theme-info .datepicker table thead tr:first-child th {
	background-color: #03A9F4;
	color: #fff; }

.dp-theme-info .datepicker table thead tr:last-child th {
	background-color: #0398db;
	color: #fff; }

.dp-theme-info .datepicker thead tr:first-child th:hover, .dp-theme-info .datepicker tfoot tr th:hover {
	background-color: #03A9F4;
	color: #fff; }

.dp-theme-info .datepicker table tr td.active:hover, .dp-theme-info .datepicker table tr td.active:hover:hover, .dp-theme-info .datepicker table tr td.active.disabled:hover, .dp-theme-info .datepicker table tr td.active.disabled:hover:hover, .dp-theme-info .datepicker table tr td.active:focus, .dp-theme-info .datepicker table tr td.active:hover:focus, .dp-theme-info .datepicker table tr td.active.disabled:focus, .dp-theme-info .datepicker table tr td.active.disabled:hover:focus, .dp-theme-info .datepicker table tr td.active:active, .dp-theme-info .datepicker table tr td.active:hover:active, .dp-theme-info .datepicker table tr td.active.disabled:active, .dp-theme-info .datepicker table tr td.active.disabled:hover:active, .dp-theme-info .datepicker table tr td.active.active, .dp-theme-info .datepicker table tr td.active:hover.active, .dp-theme-info .datepicker table tr td.active.disabled.active, .dp-theme-info .datepicker table tr td.active.disabled:hover.active, .dp-theme-info .open .dropdown-toggle.datepicker table tr td.active, .dp-theme-info .open .dropdown-toggle.datepicker table tr td.active:hover, .dp-theme-info .open .dropdown-toggle.datepicker table tr td.active.disabled, .dp-theme-info .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-info .datepicker .datepicker-switch:hover, .dp-theme-info .datepicker .prev:hover, .dp-theme-info .datepicker .next:hover, .dp-theme-info .datepicker tfoot tr th:hover {
	background-color: #03A9F4;
	color: #fff; }

.dp-theme-info.datepicker-standalone .datepicker .datepicker-switch, .dp-theme-info.datepicker-standalone .datepicker .prev, .dp-theme-info.datepicker-standalone .datepicker .next, .dp-theme-info.datepicker-standalone .datepicker tfoot tr th {
	background-color: #03A9F4;
	color: #fff; }

.dp-theme-info .datepicker table tr td span.active:hover, .dp-theme-info .datepicker table tr td span.active:hover:hover, .dp-theme-info .datepicker table tr td span.active.disabled:hover, .dp-theme-info .datepicker table tr td span.active.disabled:hover:hover, .dp-theme-info .datepicker table tr td span.active:focus, .dp-theme-info .datepicker table tr td span.active:hover:focus, .dp-theme-info .datepicker table tr td span.active.disabled:focus, .dp-theme-info .datepicker table tr td span.active.disabled:hover:focus, .dp-theme-info .datepicker table tr td span.active:active, .dp-theme-info .datepicker table tr td span.active:hover:active, .dp-theme-info .datepicker table tr td span.active.disabled:active, .dp-theme-info .datepicker table tr td span.active.disabled:hover:active, .dp-theme-info .datepicker table tr td span.active.active, .dp-theme-info .datepicker table tr td span.active:hover.active, .dp-theme-info .datepicker table tr td span.active.disabled.active, .dp-theme-info .datepicker table tr td span.active.disabled:hover.active, .dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active, .dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active:hover, .dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active.disabled, .dp-theme-info .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #03A9F4;
	color: #fff; }

.dp-theme-info .datepicker tbody tr td {
	padding: 12px 12px; }
	.dp-theme-info .datepicker tbody tr td.selected, .dp-theme-info .datepicker tbody tr td.selected:hover, .dp-theme-info .datepicker tbody tr td.selected.disabled, .dp-theme-info .datepicker tbody tr td.selected.disabled:hover {
		color: #03A9F4; }

.dp-theme-danger .datepicker table thead tr:first-child th {
	background-color: #FF5252;
	color: #fff; }

.dp-theme-danger .datepicker table thead tr:last-child th {
	background-color: #ff3939;
	color: #fff; }

.dp-theme-danger .datepicker thead tr:first-child th:hover, .dp-theme-danger .datepicker tfoot tr th:hover {
	background-color: #FF5252;
	color: #fff; }

.dp-theme-danger .datepicker table tr td.active:hover, .dp-theme-danger .datepicker table tr td.active:hover:hover, .dp-theme-danger .datepicker table tr td.active.disabled:hover, .dp-theme-danger .datepicker table tr td.active.disabled:hover:hover, .dp-theme-danger .datepicker table tr td.active:focus, .dp-theme-danger .datepicker table tr td.active:hover:focus, .dp-theme-danger .datepicker table tr td.active.disabled:focus, .dp-theme-danger .datepicker table tr td.active.disabled:hover:focus, .dp-theme-danger .datepicker table tr td.active:active, .dp-theme-danger .datepicker table tr td.active:hover:active, .dp-theme-danger .datepicker table tr td.active.disabled:active, .dp-theme-danger .datepicker table tr td.active.disabled:hover:active, .dp-theme-danger .datepicker table tr td.active.active, .dp-theme-danger .datepicker table tr td.active:hover.active, .dp-theme-danger .datepicker table tr td.active.disabled.active, .dp-theme-danger .datepicker table tr td.active.disabled:hover.active, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active:hover, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active.disabled, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-danger .datepicker .datepicker-switch:hover, .dp-theme-danger .datepicker .prev:hover, .dp-theme-danger .datepicker .next:hover, .dp-theme-danger .datepicker tfoot tr th:hover {
	background-color: #FF5252;
	color: #fff; }

.dp-theme-danger.datepicker-standalone .datepicker .datepicker-switch, .dp-theme-danger.datepicker-standalone .datepicker .prev, .dp-theme-danger.datepicker-standalone .datepicker .next, .dp-theme-danger.datepicker-standalone .datepicker tfoot tr th {
	background-color: #FF5252;
	color: #fff; }

.dp-theme-danger .datepicker table tr td span.active:hover, .dp-theme-danger .datepicker table tr td span.active:hover:hover, .dp-theme-danger .datepicker table tr td span.active.disabled:hover, .dp-theme-danger .datepicker table tr td span.active.disabled:hover:hover, .dp-theme-danger .datepicker table tr td span.active:focus, .dp-theme-danger .datepicker table tr td span.active:hover:focus, .dp-theme-danger .datepicker table tr td span.active.disabled:focus, .dp-theme-danger .datepicker table tr td span.active.disabled:hover:focus, .dp-theme-danger .datepicker table tr td span.active:active, .dp-theme-danger .datepicker table tr td span.active:hover:active, .dp-theme-danger .datepicker table tr td span.active.disabled:active, .dp-theme-danger .datepicker table tr td span.active.disabled:hover:active, .dp-theme-danger .datepicker table tr td span.active.active, .dp-theme-danger .datepicker table tr td span.active:hover.active, .dp-theme-danger .datepicker table tr td span.active.disabled.active, .dp-theme-danger .datepicker table tr td span.active.disabled:hover.active, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active:hover, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active.disabled, .dp-theme-danger .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #FF5252;
	color: #fff; }

.dp-theme-danger .datepicker tbody tr td {
	padding: 12px 12px; }
	.dp-theme-danger .datepicker tbody tr td.selected, .dp-theme-danger .datepicker tbody tr td.selected:hover, .dp-theme-danger .datepicker tbody tr td.selected.disabled, .dp-theme-danger .datepicker tbody tr td.selected.disabled:hover {
		color: #FF5252; }

.dp-theme-pink .datepicker table thead tr:first-child th {
	background-color: #E91E63;
	color: #fff; }

.dp-theme-pink .datepicker table thead tr:last-child th {
	background-color: #d81558;
	color: #fff; }

.dp-theme-pink .datepicker thead tr:first-child th:hover, .dp-theme-pink .datepicker tfoot tr th:hover {
	background-color: #E91E63;
	color: #fff; }

.dp-theme-pink .datepicker table tr td.active:hover, .dp-theme-pink .datepicker table tr td.active:hover:hover, .dp-theme-pink .datepicker table tr td.active.disabled:hover, .dp-theme-pink .datepicker table tr td.active.disabled:hover:hover, .dp-theme-pink .datepicker table tr td.active:focus, .dp-theme-pink .datepicker table tr td.active:hover:focus, .dp-theme-pink .datepicker table tr td.active.disabled:focus, .dp-theme-pink .datepicker table tr td.active.disabled:hover:focus, .dp-theme-pink .datepicker table tr td.active:active, .dp-theme-pink .datepicker table tr td.active:hover:active, .dp-theme-pink .datepicker table tr td.active.disabled:active, .dp-theme-pink .datepicker table tr td.active.disabled:hover:active, .dp-theme-pink .datepicker table tr td.active.active, .dp-theme-pink .datepicker table tr td.active:hover.active, .dp-theme-pink .datepicker table tr td.active.disabled.active, .dp-theme-pink .datepicker table tr td.active.disabled:hover.active, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active:hover, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active.disabled, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-pink .datepicker .datepicker-switch:hover, .dp-theme-pink .datepicker .prev:hover, .dp-theme-pink .datepicker .next:hover, .dp-theme-pink .datepicker tfoot tr th:hover {
	background-color: #E91E63;
	color: #fff; }

.dp-theme-pink.datepicker-standalone .datepicker .datepicker-switch, .dp-theme-pink.datepicker-standalone .datepicker .prev, .dp-theme-pink.datepicker-standalone .datepicker .next, .dp-theme-pink.datepicker-standalone .datepicker tfoot tr th {
	background-color: #E91E63;
	color: #fff; }

.dp-theme-pink .datepicker table tr td span.active:hover, .dp-theme-pink .datepicker table tr td span.active:hover:hover, .dp-theme-pink .datepicker table tr td span.active.disabled:hover, .dp-theme-pink .datepicker table tr td span.active.disabled:hover:hover, .dp-theme-pink .datepicker table tr td span.active:focus, .dp-theme-pink .datepicker table tr td span.active:hover:focus, .dp-theme-pink .datepicker table tr td span.active.disabled:focus, .dp-theme-pink .datepicker table tr td span.active.disabled:hover:focus, .dp-theme-pink .datepicker table tr td span.active:active, .dp-theme-pink .datepicker table tr td span.active:hover:active, .dp-theme-pink .datepicker table tr td span.active.disabled:active, .dp-theme-pink .datepicker table tr td span.active.disabled:hover:active, .dp-theme-pink .datepicker table tr td span.active.active, .dp-theme-pink .datepicker table tr td span.active:hover.active, .dp-theme-pink .datepicker table tr td span.active.disabled.active, .dp-theme-pink .datepicker table tr td span.active.disabled:hover.active, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active:hover, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active.disabled, .dp-theme-pink .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #E91E63;
	color: #fff; }

.dp-theme-pink .datepicker tbody tr td {
	padding: 12px 12px; }
	.dp-theme-pink .datepicker tbody tr td.selected, .dp-theme-pink .datepicker tbody tr td.selected:hover, .dp-theme-pink .datepicker tbody tr td.selected.disabled, .dp-theme-pink .datepicker tbody tr td.selected.disabled:hover {
		color: #E91E63; }

.dp-theme-purple .datepicker table thead tr:first-child th {
	background-color: #7E57C2;
	color: #fff; }

.dp-theme-purple .datepicker table thead tr:last-child th {
	background-color: #7044bb;
	color: #fff; }

.dp-theme-purple .datepicker thead tr:first-child th:hover, .dp-theme-purple .datepicker tfoot tr th:hover {
	background-color: #7E57C2;
	color: #fff; }

.dp-theme-purple .datepicker table tr td.active:hover, .dp-theme-purple .datepicker table tr td.active:hover:hover, .dp-theme-purple .datepicker table tr td.active.disabled:hover, .dp-theme-purple .datepicker table tr td.active.disabled:hover:hover, .dp-theme-purple .datepicker table tr td.active:focus, .dp-theme-purple .datepicker table tr td.active:hover:focus, .dp-theme-purple .datepicker table tr td.active.disabled:focus, .dp-theme-purple .datepicker table tr td.active.disabled:hover:focus, .dp-theme-purple .datepicker table tr td.active:active, .dp-theme-purple .datepicker table tr td.active:hover:active, .dp-theme-purple .datepicker table tr td.active.disabled:active, .dp-theme-purple .datepicker table tr td.active.disabled:hover:active, .dp-theme-purple .datepicker table tr td.active.active, .dp-theme-purple .datepicker table tr td.active:hover.active, .dp-theme-purple .datepicker table tr td.active.disabled.active, .dp-theme-purple .datepicker table tr td.active.disabled:hover.active, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active:hover, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active.disabled, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dp-theme-purple .datepicker .datepicker-switch:hover, .dp-theme-purple .datepicker .prev:hover, .dp-theme-purple .datepicker .next:hover, .dp-theme-purple .datepicker tfoot tr th:hover {
	background-color: #7E57C2;
	color: #fff; }

.dp-theme-purple.datepicker-standalone .datepicker .datepicker-switch, .dp-theme-purple.datepicker-standalone .datepicker .prev, .dp-theme-purple.datepicker-standalone .datepicker .next, .dp-theme-purple.datepicker-standalone .datepicker tfoot tr th {
	background-color: #7E57C2;
	color: #fff; }

.dp-theme-purple .datepicker table tr td span.active:hover, .dp-theme-purple .datepicker table tr td span.active:hover:hover, .dp-theme-purple .datepicker table tr td span.active.disabled:hover, .dp-theme-purple .datepicker table tr td span.active.disabled:hover:hover, .dp-theme-purple .datepicker table tr td span.active:focus, .dp-theme-purple .datepicker table tr td span.active:hover:focus, .dp-theme-purple .datepicker table tr td span.active.disabled:focus, .dp-theme-purple .datepicker table tr td span.active.disabled:hover:focus, .dp-theme-purple .datepicker table tr td span.active:active, .dp-theme-purple .datepicker table tr td span.active:hover:active, .dp-theme-purple .datepicker table tr td span.active.disabled:active, .dp-theme-purple .datepicker table tr td span.active.disabled:hover:active, .dp-theme-purple .datepicker table tr td span.active.active, .dp-theme-purple .datepicker table tr td span.active:hover.active, .dp-theme-purple .datepicker table tr td span.active.disabled.active, .dp-theme-purple .datepicker table tr td span.active.disabled:hover.active, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active:hover, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active.disabled, .dp-theme-purple .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #7E57C2;
	color: #fff; }

.dp-theme-purple .datepicker tbody tr td {
	padding: 12px 12px; }
	.dp-theme-purple .datepicker tbody tr td.selected, .dp-theme-purple .datepicker tbody tr td.selected:hover, .dp-theme-purple .datepicker tbody tr td.selected.disabled, .dp-theme-purple .datepicker tbody tr td.selected.disabled:hover {
		color: #7E57C2; }

/**
 * Summernote
 */
.note-editor .note-editing-area .note-editable {
	color: inherit !important; }

.note-editor .note-statusbar {
	background-color: rgba(162, 162, 162, 0.26) !important; }

.note-editor.note-frame {
	border-color: rgba(162, 162, 162, 0.26) !important; }

.note-editor .dropdown-menu {
	max-height: 280px;
	overflow: auto; }

.summernote-air + .note-editor .note-editing-area .note-editable {
	background-color: transparent !important; }

.panel-heading.note-toolbar {
	padding: 1.6rem 2.4rem; }

.note-btn-group .btn-default {
	color: #59676b;
	background-color: #fff;
	border-color: #ECEFF1; }
	.note-btn-group .btn-default:hover {
		color: #59676b;
		background-color: whitesmoke;
		border-color: #e0e5e8; }
	.note-btn-group .btn-default:focus, .note-btn-group .btn-default.focus {
		box-shadow: 0 0 0 2px rgba(236, 239, 241, 0.5); }
	.note-btn-group .btn-default.disabled, .note-btn-group .btn-default:disabled {
		background-color: #fff;
		border-color: #ECEFF1; }
	.note-btn-group .btn-default:active, .note-btn-group .btn-default.active,
	.show > .note-btn-group .btn-default.dropdown-toggle {
		color: #59676b;
		background-color: whitesmoke;
		background-image: none;
		border-color: #e0e5e8; }

.note-btn-group .dropdown-menu > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.42857143;
	color: #333;
	white-space: nowrap; }

.note-popover .popover-content .note-color .dropdown-toggle,
.note-editor .panel-heading.note-toolbar .note-color .dropdown-toggle {
	width: 20px;
	padding-left: 1px; }

/**
 * Typeahead.js
 */
.twitter-typeahead {
	width: 100%; }
	.twitter-typeahead .tt-menu {
		width: 100%;
		background-color: #fff;
		border: 1px solid rgba(160, 160, 160, 0.5); }
	.twitter-typeahead .tt-suggestion {
		padding: .4rem 1rem; }
		.twitter-typeahead .tt-suggestion:hover {
			background-color: rgba(160, 160, 160, 0.12); }

.twitter-typeahead {
	display: block !important; }

#multiple-datasets .league-name {
	margin: 0;
	padding: 1rem;
	border-bottom: 1px solid rgba(160, 160, 160, 0.25); }

#scrollable-dropdown-menu .tt-menu {
	max-height: 150px;
	overflow-y: auto; }

/**
 * Forms Validation
 */
.form-validate .form-control.error {
	border-color: #FF5252; }

.form-validate .form-control.valid, .form-validate .form-control.valid:focus {
	border-color: #4CAF50; }

.form-validate .has-error .form-control {
	border-color: #FF5252 !important; }

.form-validate label + label.error {
	display: block; }

/**
 * Form Wizard (Steps)
 */
.wizard, .tabcontrol {
	display: block;
	width: 100%;
	overflow: hidden; }

.wizard a, .tabcontrol a {
	outline: 0; }

.wizard ul, .tabcontrol ul {
	list-style: none !important;
	padding: 0;
	margin: 0; }

.wizard ul > li, .tabcontrol ul > li {
	display: block;
	padding: 0; }

.wizard > .steps .current-info, .tabcontrol > .steps .current-info {
	position: absolute;
	left: -999em; }

.wizard > .content > .title, .tabcontrol > .content > .title {
	position: absolute;
	left: -999em; }

.wizard > .steps {
	position: relative;
	display: block;
	width: 100%; }
	.wizard > .steps .number {
		font-size: 1.429em; }
	.wizard > .steps > ul > li {
		width: 25%; }

.wizard > .content {
	background-color: transparent;
	display: block;
	margin: 0.5em;
	min-height: 350px;
	overflow: hidden;
	position: relative;
	width: auto;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px; }
	.wizard > .content > .body {
		float: left;
		position: absolute;
		width: 100%;
		height: 95%;
		padding: 2.5% 0; }
		.wizard > .content > .body ul {
			list-style: disc !important; }
			.wizard > .content > .body ul > li {
				display: list-item; }
		.wizard > .content > .body > iframe {
			border: 0 none;
			width: 100%;
			height: 100%; }
		.wizard > .content > .body input.error {
			border-color: #FF5252; }
		.wizard > .content > .body input.valid {
			border-color: #4CAF50; }
		.wizard > .content > .body label {
			display: inline-block;
			margin-bottom: 0.5em; }
			.wizard > .content > .body label.error {
				color: #FF5252;
				display: block !important;
				padding: 0; }
			.wizard > .content > .body label.valid {
				border-color: #4CAF50; }

.wizard > .actions {
	position: relative;
	display: block;
	text-align: right;
	width: 100%; }
	.wizard > .actions > ul {
		display: inline-block;
		text-align: right; }
		.wizard > .actions > ul > li {
			margin: 0 0.5em; }

.wizard.vertical > .steps {
	display: inline;
	float: left;
	width: 30%; }
	.wizard.vertical > .steps > ul > li {
		float: none;
		width: 100%; }
	.wizard.vertical > .steps .current a, .wizard.vertical > .steps .current a:hover, .wizard.vertical > .steps .current a:active {
		border-left: 2px solid #448AFF;
		border-bottom: 0; }

.wizard.vertical > .content {
	display: inline;
	float: left;
	margin: 0 2.5% 0.5em 2.5%;
	width: 65%; }

.wizard.vertical > .actions {
	display: inline;
	float: right;
	margin: 0 2.5%;
	width: 95%; }
	.wizard.vertical > .actions > ul > li {
		margin: 0 0 0 1em; }

.wizard > .steps > ul > li, .wizard > .actions > ul > li {
	float: left; }

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
	display: block;
	width: auto;
	margin: 0 0.5em 0.5em;
	padding: 1em 1em;
	text-decoration: none;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 0;
	font-weight: bold;
	color: inherit; }
	.wizard > .steps a > small, .wizard > .steps a:hover > small, .wizard > .steps a:active > small {
		font-weight: normal;
		color: #a2a2a2; }

.wizard > .steps .disabled a, .wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
	background: transparent;
	color: inherit;
	cursor: default; }

.wizard > .steps .current a, .wizard > .steps .current a:hover, .wizard > .steps .current a:active {
	border-bottom: 2px solid #1E88E5;
	cursor: default; }

.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
	background: transparent;
	color: inherit; }

.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
	display: inline-block;
	padding: 6px 16px;
	font-size: 13px;
	border-radius: 3px;
	text-decoration: none;
	color: #fff;
	background: #1E88E5;
	border: 0; }

.wizard > .actions .disabled a, .wizard > .actions .disabled a:hover, .wizard > .actions .disabled a:active {
	color: inherit;
	background: rgba(160, 160, 160, 0.25);
	border: 0; }

.tabcontrol > .steps {
	position: relative;
	display: block;
	width: 100%; }
	.tabcontrol > .steps > ul {
		position: relative;
		margin: 6px 0 0 0;
		top: 1px;
		z-index: 1; }
		.tabcontrol > .steps > ul > li {
			float: left;
			margin: 5px 2px 0 0;
			padding: 1px;
			-webkit-border-top-left-radius: 5px;
			-webkit-border-top-right-radius: 5px;
			-moz-border-radius-topleft: 5px;
			-moz-border-radius-topright: 5px;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px; }
			.tabcontrol > .steps > ul > li > a {
				color: #5f5f5f;
				display: inline-block;
				border: 0 none;
				margin: 0;
				padding: 10px 30px;
				text-decoration: none; }
			.tabcontrol > .steps > ul > li > a:hover {
				text-decoration: none; }
		.tabcontrol > .steps > ul > li:hover {
			background: #edecec;
			border: 1px solid #bbb;
			padding: 0; }
		.tabcontrol > .steps > ul > li.current {
			background: #fff;
			border: 1px solid #bbb;
			border-bottom: 0 none;
			padding: 0 0 1px 0;
			margin-top: 0; }
			.tabcontrol > .steps > ul > li.current > a {
				padding: 15px 30px 10px 30px; }

.tabcontrol > .content {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 300px;
	overflow: hidden;
	border-top: 1px solid #bbb;
	padding-top: 20px; }
	.tabcontrol > .content > .body {
		float: left;
		position: absolute;
		width: 95%;
		height: 95%;
		padding: 2.5%; }
		.tabcontrol > .content > .body ul {
			list-style: disc !important; }
			.tabcontrol > .content > .body ul > li {
				display: list-item; }

/**
 * Forms Xeditable
 */
[data-type="textarea"] + .editable-container.editable-inline {
	width: 100%; }
	[data-type="textarea"] + .editable-container.editable-inline .editableform .control-group,
	[data-type="textarea"] + .editable-container.editable-inline .editable-input,
	[data-type="textarea"] + .editable-container.editable-inline .editableform textarea.form-control {
		width: 100%; }

/**
 * Header menu links (animated hamburguer toggles)
 */
.header-container > nav > ul > li > .menu-link {
	height: 60px;
	padding-top: 25px; }

.menu-link-close {
	display: block;
	position: relative; }
	.menu-link-close span {
		position: relative;
		display: block; }
	.menu-link-close span,
	.menu-link-close span:before,
	.menu-link-close span:after {
		width: 18px;
		height: 0;
		border-bottom: 2px solid;
		will-change: transform;
		transition: transform 0.4s; }
	.menu-link-close span {
		transition: border-color 0.4s; }
	.menu-link-close span:before,
	.menu-link-close span:after {
		content: " ";
		position: absolute; }
	.menu-link-close span:before {
		top: 6px; }
	.menu-link-close span:after {
		top: 12px; }

.active .menu-link-close span {
	border-color: transparent; }

.active .menu-link-close span:before {
	-webkit-transform: rotate(45deg) translate(1px, 0px);
	-moz-transform: rotate(45deg) translate(1px, 0px);
	-ms-transform: rotate(45deg) translate(1px, 0px);
	-o-transform: rotate(45deg) translate(1px, 0px);
	transform: rotate(45deg) translate(1px, 0px); }

.active .menu-link-close span:after {
	-webkit-transform: rotate(-45deg) translate(4px, -3px);
	-moz-transform: rotate(-45deg) translate(4px, -3px);
	-ms-transform: rotate(-45deg) translate(4px, -3px);
	-o-transform: rotate(-45deg) translate(4px, -3px);
	transform: rotate(-45deg) translate(4px, -3px); }

/**
 * Header
 */
.header-container {
	position: relative;
	background-color: #fff;
	z-index: 1; }
	@media only screen and (min-width: 992px) {
		.header-container {
			margin-left: 240px; } }
	.header-container > nav {
		min-height: 60px;
		line-height: 60px;
		box-shadow: 0px 5px 25px 0px rgba(123, 123, 123, 0.15); }
		.header-container > nav::after {
			display: block;
			content: "";
			clear: both; }
		.header-container > nav > .header-title {
			display: none;
			position: relative;
			float: left;
			margin: 0;
			padding: 0 .5rem;
			font-size: 1.2rem;
			line-height: inherit;
			letter-spacing: -0.02em;
			font-weight: 400; }
			@media (min-width: 340px) {
				.header-container > nav > .header-title {
					display: block; } }
			@media (max-width: 420px) {
				.header-container > nav > .header-title {
					max-width: 110px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden; } }
			@media only screen and (min-width: 768px) {
				.header-container > nav > .header-title {
					padding: 0 1rem;
					font-size: 1.5rem; } }
		.header-container > nav > ul {
			float: left;
			list-style-type: none;
			margin: 0;
			padding: 0; }
			.header-container > nav > ul::after {
				display: block;
				content: "";
				clear: both; }
			.header-container > nav > ul > li {
				float: left; }
				.header-container > nav > ul > li > a {
					display: block;
					padding: 0 1rem;
					text-decoration: none;
					color: inherit; }
					.header-container > nav > ul > li > a > em {
						font-size: 1.3rem;
						vertical-align: -24%;
						line-height: 1; }
					.header-container > nav > ul > li > a > .header-user-image {
						width: 32px;
						height: 32px;
						border-radius: 50%; }
						.header-container > nav > ul > li > a > .header-user-image + span {
							margin-left: 14px; }
				.header-container > nav > ul > li > form,
				.header-container > nav > ul > li > button {
					margin: 0 1rem; }
				.header-container > nav > ul > li > form {
					margin-top: 1.15rem; }
			.header-container > nav > ul > li > a:hover {
				background-color: rgba(0, 0, 0, 0.025); }

.has-badge {
	position: relative; }
	.has-badge .badge {
		position: absolute;
		top: 18%;
		left: 38px; }
	.has-badge.dropdown-toggle::after {
		display: none; }

.covermode-toggler {
	display: none !important; }

.sidebar-close {
	display: none !important; }

@media only screen and (min-width: 992px) {
	.sidebar-cover .covermode-toggler {
		display: block !important; }
	.sidebar-cover .sidebar-cover .sidebar-close {
		display: block !important; } }

.modal-search-form {
	margin: 0 auto; }
	@media only screen and (min-width: 768px) {
		.modal-search-form {
			max-width: 70%; } }
	.modal-search-form .input-group-btn > .btn {
		padding-left: 0; }

/**
 * Google Maps
 */
.gmap,
.angular-google-map-container {
	height: 300px; }
	.gmap.gmap-md,
	.angular-google-map-container.gmap-md {
		height: 240px; }
	.gmap.gmap-sm,
	.angular-google-map-container.gmap-sm {
		height: 150px; }

.fh .angular-google-map,
.fh .angular-google-map-container {
	height: 100% !important; }

/**
 * Vector Maps
 */
.vector-map {
	height: 400px; }
	.vector-map svg {
		width: 100%; }
	.vector-map .jvectormap-container {
		background-color: transparent !important; }
	.vector-map .jvectormap-zoomin,
	.vector-map .jvectormap-zoomout {
		position: absolute;
		left: 10px;
		width: 22px;
		height: 22px;
		border-radius: 2px;
		background: #515253;
		padding: 5px;
		color: white;
		cursor: pointer;
		line-height: 10px;
		text-align: center; }
	.vector-map .jvectormap-zoomin {
		top: 10px; }
	.vector-map .jvectormap-zoomout {
		top: 30px; }

.jvectormap-label {
	position: absolute;
	display: none;
	border: solid 1px #313232;
	border-radius: 2px;
	background: #313232;
	color: white;
	padding: 3px 6px;
	opacity: 0.9;
	z-index: 11; }

/**
 * Calendar
 */
.calendar-app .fc-button {
	background-image: none;
	background-color: rgba(160, 160, 160, 0.25);
	color: inherit;
	box-shadow: 0 0 0 #000;
	border-radius: 2px;
	font-size: .9rem;
	padding: .75rem 1rem;
	height: 3rem;
	line-height: 1.7rem;
	border: 1px solid rgba(160, 160, 160, 0.25);
	text-shadow: none; }
	.calendar-app .fc-button + .fc-button {
		margin-left: 4px; }
	.calendar-app .fc-button.fc-state-active {
		background-color: #1E88E5;
		color: #fff; }
	@media only screen and (max-width: 767px) {
		.calendar-app .fc-button {
			padding: .25rem .5rem;
			height: 2rem;
			font-size: .7rem; } }

.calendar-app .fc-toolbar .fc-center {
	margin-top: 1rem; }
	@media only screen and (min-width: 480px) {
		.calendar-app .fc-toolbar .fc-center {
			margin-top: .5rem; } }

.calendar-app .fc-event, .calendar-app .fc-event-dot {
	color: #59676b;
	background-color: rgba(30, 136, 229, 0.3);
	border: 0;
	border-left: 2px solid #1E88E5;
	padding: 4px;
	border-radius: 1px; }

.calendar-app .fc-unthemed td.fc-today {
	background-color: transparent; }
	.calendar-app .fc-unthemed td.fc-today .fc-day-number {
		position: relative;
		background-color: #03A9F4;
		top: 1px;
		right: 1px;
		color: #fff;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		border-radius: 500px;
		margin-bottom: 6px; }

.calendar-app .fc-unthemed th,
.calendar-app .fc-unthemed td,
.calendar-app .fc-unthemed thead,
.calendar-app .fc-unthemed tbody,
.calendar-app .fc-unthemed .fc-divider,
.calendar-app .fc-unthemed .fc-row,
.calendar-app .fc-unthemed .fc-content,
.calendar-app .fc-unthemed .fc-popover,
.calendar-app .fc-unthemed .fc-list-view,
.calendar-app .fc-unthemed .fc-list-heading td {
	border-color: rgba(160, 160, 160, 0.25); }

/**
 * Timeline
 */
.timeline {
	list-style: none;
	padding: 0 0 20px;
	position: relative;
	z-index: 0; }
	.timeline:before {
		position: absolute;
		top: 0;
		bottom: 0;
		content: " ";
		width: 1px;
		border-left: 1px dashed rgba(162, 162, 162, 0.36);
		left: 10px; }
	.timeline .timeline-end {
		float: none;
		clear: both; }
	.timeline > li {
		margin-bottom: 20px; }
		.timeline > li::after {
			display: block;
			content: "";
			clear: both; }
		.timeline > li.timeline-separator {
			position: relative;
			float: none;
			clear: both;
			margin: 0 auto;
			padding: 40px 0; }
			.timeline > li.timeline-separator:first-child {
				padding-top: 0; }
			.timeline > li.timeline-separator:before {
				content: attr(data-datetime);
				display: inline-block;
				/*width: 120px;*/
				text-align: center;
				padding: 0 20px;
				line-height: 30px;
				background-color: #90A4AE;
				border-radius: 2px;
				color: #fff; }
	.timeline > li > .timeline-panel {
		margin-left: 40px; }
	.timeline > li > .timeline-date {
		margin: -20px 0 0;
		display: block;
		height: 20px;
		line-height: 20px;
		font-size: 13px; }
		.timeline > li > .timeline-date > time:after {
			content: attr(datetime); }
	.timeline > li > .timeline-badge {
		position: absolute;
		left: 0;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		line-height: 25px;
		font-size: 1.1em;
		text-align: center;
		color: #fff;
		z-index: 100; }

.timeline-title {
	margin-top: 0;
	color: inherit; }

.timeline-body > p, .timeline-body > ul {
	margin-bottom: 0; }

.timeline-body > p + p {
	margin-top: 5px; }

/**
 * Settings
 */
.setting-color {
	padding: 0 5px; }
	.setting-color > label {
		display: block;
		position: relative;
		margin: 0 10px;
		border-radius: 1px;
		cursor: pointer;
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }
		.setting-color > label:first-child {
			margin-left: 0; }
		.setting-color > label:last-child {
			margin-right: 0; }
		.setting-color > label .t-grid, .setting-color > label .t-row {
			max-width: 60px;
			height: 100%;
			margin: 0; }
		.setting-color > label > .color {
			display: block;
			height: 18px; }
		.setting-color > label > .split {
			display: block; }
			.setting-color > label > .split::after {
				display: block;
				content: "";
				clear: both; }
			.setting-color > label > .split > .color {
				display: block;
				height: 37.5px; }
				.setting-color > label > .split > .color:first-child {
					float: left;
					width: 70%; }
				.setting-color > label > .split > .color:last-child {
					float: right;
					width: 30%; }
		.setting-color > label > .ion-checkmark-round {
			position: absolute;
			display: block;
			right: -5px;
			top: -10px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			text-align: center;
			font-size: 14px;
			color: #4CAF50;
			background-color: #fff;
			box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
			transition: transform .2s ease;
			transform: scale(0); }
		.setting-color > label > input[type="radio"] {
			position: absolute;
			visibility: hidden; }
			.setting-color > label > input[type="radio"]:checked + .ion-checkmark-round {
				opacity: 1 !important;
				transform: scale(1); }

/**
 * Sidebar
 */
.sidebar-container {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 240px;
	overflow-y: auto;
	overflow-x: visible;
	-webkit-overflow-scrolling: touch;
	background-color: #263238;
	color: #fff;
	z-index: 40; }
	.sidebar-container a.sb-link {
		color: rgba(255, 255, 255, 0.75);
		text-decoration: none; }

.brand-header {
	height: 60px;
	padding: 0 1rem;
	text-align: center;
	box-shadow: 0 0 0 #000; }
	.brand-header > .brand-header-logo {
		display: block;
		height: 100%;
		text-decoration: none;
		color: inherit;
		padding: 1rem 0; }
		.brand-header > .brand-header-logo > img,
		.brand-header > .brand-header-logo > svg {
			width: auto;
			max-height: 100%; }
			.brand-header > .brand-header-logo > img + .brand-header-logo-text,
			.brand-header > .brand-header-logo > svg + .brand-header-logo-text {
				margin-left: .5rem; }
		.brand-header > .brand-header-logo > svg {
			fill: #fff; }
		.brand-header > .brand-header-logo > .brand-header-logo-text {
			font-size: 22px;
			letter-spacing: .023em;
			font-weight: 500;
			font-size: 20px; }
		.brand-header > .brand-header-logo > img,
		.brand-header > .brand-header-logo > .brand-header-logo-text {
			display: inline-block;
			vertical-align: middle; }

.brand-header + .sidebar-content {
	height: calc(100% - 60px);
	overflow: auto; }
.brand-header + .sidebar-content:hover{
	overflow: auto;
}

.sidebar-content {
	box-shadow: -1px 0 0 rgba(160, 160, 160, 0.12) inset; }

.sidebar-toolbar {
	position: relative; }
	.sidebar-toolbar .sidebar-toolbar-content {
		position: relative;
		padding: 2rem 1rem;
		z-index: 3; }
	.sidebar-toolbar .sidebar-toolbar-background {
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #263238;
		/*background-image: url("../img/profile-bg.jpg");*/
		background-repeat: no-repeat;
		background-position: left top;
		background-size: cover;
		opacity: 0.5;
		z-index: 1; }
	.sidebar-toolbar + .sidebar-nav {
		padding-top: 0; }

.sidebar-container {
	transform: translate3d(0, 0, 0);
	transition: transform .28s ease-in-out;
	will-change: transform; }
	@media only screen and (max-width: 991px) and (min-width: 1px) {
		.sidebar-container {
			transform: translate3d(-100%, 0, 0); } }

.sidebar-layout-obfuscator {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 30;
	visibility: hidden;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.3);
	will-change: opacity;
	transition: opacity .28s ease-in-out, visibility .28s ease-in-out; }

.sidebar-visible, .sidebar-cover-visible {
	overflow: hidden; }
	.sidebar-visible .sidebar-container, .sidebar-cover-visible .sidebar-container {
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
		transform: translate3d(0, 0, 0); }
	.sidebar-visible .sidebar-layout-obfuscator, .sidebar-cover-visible .sidebar-layout-obfuscator {
		opacity: 1;
		visibility: visible; }

.no-csstransforms3d .sidebar-container {
	margin-left: -240px; }
	@media only screen and (min-width: 992px) {
		.no-csstransforms3d .sidebar-container {
			margin-left: 0; } }

@media only screen and (max-width: 1199px) {
	.no-csstransforms3d .sidebar-visible .sidebar-container {
		margin-left: 0; } }

.sidebar-nav {
	padding-top: 16px;
	font-weight: 500;
	background-color: inherit; }
	.sidebar-nav ul {
		list-style-type: none;
		margin: 0;
		padding: 0; }
		.sidebar-nav ul > li > .sidebar-nav-heading {
			padding: 1.2rem 1rem;
			font-size: .8rem;
			font-weight: bold;
			letter-spacing: .045rem; }
		.sidebar-nav ul > li > a {
			display: block;
			padding: 0 1rem;
			border-left: 4px solid transparent;
			height: 50px;
			line-height: 50px;
			text-decoration: none;
			letter-spacing: 0.0125em;
			color: inherit;
			overflow: hidden;
			cursor: default; }
			.sidebar-nav ul > li > a > .nav-icon {
				position: relative;
				display: inline-block;
				width: 1em;
				height: 1em;
				margin-right: 16px;
				text-align: center;
				font-size: 14pt;
				vertical-align: middle; }
				.sidebar-nav ul > li > a > .nav-icon > em,
				.sidebar-nav ul > li > a > .nav-icon > img,
				.sidebar-nav ul > li > a > .nav-icon > svg {
					position: absolute;
					display: inline-block;
					color: inherit;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					line-height: 1; }
				.sidebar-nav ul > li > a > .nav-icon > svg {
					color: #868B90;
					fill: #64B5F6; }
				.sidebar-nav ul > li > a > .nav-icon > em {
					color: #868B90; }
			.sidebar-nav ul > li > a > .nav-label,
			.sidebar-nav ul > li > a > .nav-caret {
				line-height: inherit; }
			.sidebar-nav ul > li > a > .nav-label {
				margin-right: 16px;
				margin-top: -2px; }
			.sidebar-nav ul > li > a > .nav-caret {
				margin-left: 11px; }
				.sidebar-nav ul > li > a > .nav-caret + .nav-label {
					margin-right: 0; }
		.sidebar-nav ul > li > a:hover,
		.sidebar-nav ul > li > a:focus {
			background-color: rgba(162, 162, 162, 0.09); }
		.sidebar-nav ul > li.active > a {
			background-color: rgba(0, 0, 0, 0.025);
			border-left-color: #1E88E5; }
		.sidebar-nav ul > li.active > ul li.active > a {
			background-color: rgba(162, 162, 162, 0.2);
			font-weight: bold; }
		.sidebar-nav ul > li ul > li > a {
			padding-left: 56px;
			border-left: 0; }
		.sidebar-nav ul > li ul > li ul > li > a {
			padding-left: 72px; }
		.sidebar-nav ul > li > ul {
			height: 0;
			overflow: hidden; }
		.sidebar-nav ul > li > a > .nav-caret {
			transition-transform: .2s ease; }
		.sidebar-nav ul > li.active > ul {
			height: auto; }
			.sidebar-nav ul > li.active > ul > li > a {
				opacity: 1;
				margin-left: 0; }
		.sidebar-nav ul > li.active > a > .nav-caret {
			transform: rotate(90deg); }

/**
 * Tables - Bootgrid
 */
.bootgrid .table > thead > tr > td.active, .bootgrid .table > tbody > tr > td.active, .bootgrid .table > tfoot > tr > td.active, .bootgrid .table > thead > tr > th.active, .bootgrid .table > tbody > tr > th.active, .bootgrid .table > tfoot > tr > th.active, .bootgrid .table > thead > tr.active > td, .bootgrid .table > tbody > tr.active > td, .bootgrid .table > tfoot > tr.active > td, .bootgrid .table > thead > tr.active > th, .bootgrid .table > tbody > tr.active > th, .bootgrid .table > tfoot > tr.active > th {
	background-color: #ECEFF1; }

@media only screen and (max-width: 767px) {
	.bootgrid-header .search {
		max-width: 130px; } }

.bootgrid-header .search .input-group-addon {
	border: 0 !important;
	background-color: transparent; }

.bootgrid-header .search.form-group {
	float: left; }

.bootgrid-header .dropdown {
	color: inherit; }
	.bootgrid-header .dropdown > .btn {
		border: 0; }
	.bootgrid-header .dropdown .dropdown-item:hover {
		background-color: #1E88E5 !important;
		color: #fff !important; }
	.bootgrid-header .dropdown .dropdown-item > a {
		color: inherit; }
	.bootgrid-header .dropdown .active > .dropdown-item {
		color: #fff; }

.bootgrid-table th > .column-header-anchor {
	color: inherit; }

.bootgrid-table th:hover, .bootgrid-table th:active {
	background-color: rgba(162, 162, 162, 0.12); }

.bootgrid-table td.loading, .bootgrid-table td.no-results {
	background-color: transparent;
	color: inherit; }

.bootgrid-footer .infoBar {
	margin-top: .5rem; }

@media only screen and (max-width: 767px) {
	.bootgrid-footer .pagination {
		-webkit-box-pack: center !important;
		-webkit-justify-content: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important; }
	.bootgrid-footer .infoBar {
		text-align: center;
		margin-top: 1rem; } }

.bootgrid .table > thead > tr > td.active, .bootgrid .table > tbody > tr > td.active, .bootgrid .table > tfoot > tr > td.active, .bootgrid .table > thead > tr > th.active, .bootgrid .table > tbody > tr > th.active, .bootgrid .table > tfoot > tr > th.active, .bootgrid .table > thead > tr.active > td, .bootgrid .table > tbody > tr.active > td, .bootgrid .table > tfoot > tr.active > td, .bootgrid .table > thead > tr.active > th, .bootgrid .table > tbody > tr.active > th, .bootgrid .table > tfoot > tr.active > th {
	background-color: rgba(162, 162, 162, 0.12); }

/**
 * Tables - Datatables
 */
 /*
.dataTables_wrapper {
	clear: both; }

.dataTables_filter {
	text-align: center; }
	@media only screen and (min-width: 768px) {
		.dataTables_filter {
			float: right; } }
	.dataTables_filter label > em {
		color: inherit; }
	.dataTables_filter input {
		border: 1px solid rgba(160, 160, 160, 0.25);
		margin-left: 10px;
		padding: 0.25rem .5rem;
		background-color: transparent;
		color: inherit; }

.dataTables_length {
	text-align: center; }
	@media only screen and (min-width: 768px) {
		.dataTables_length {
			float: left;
			margin-bottom: 2rem; } }
	.dataTables_length select {
		border: 1px solid rgba(160, 160, 160, 0.25);
		margin-right: 10px;
		padding: 0.25rem .5rem;
		background-color: transparent;
		color: inherit; }

.dt-buttons {
	-webkit-box-pack: center !important;
	-webkit-justify-content: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important; }

.dataTables_info {
	text-align: center;
	padding-top: .75rem; }
	@media only screen and (min-width: 768px) {
		.dataTables_info {
			float: left; } }

.dataTables_wrapper .dataTables_paginate {
	text-align: center;
	padding-top: 0.25rem; }
	@media only screen and (min-width: 768px) {
		.dataTables_wrapper .dataTables_paginate {
			float: right;
			text-align: right; } }

.dataTables_wrapper .dataTables_paginate .paginate_button {
	display: inline-block;
	padding: 0.25rem .7rem;
	margin-left: .1rem;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: #1E88E5;
	border: none;
	border-radius: 1px; }

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: #fff;
	border: 1px solid #1E88E5;
	background-color: #1E88E5; }

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	cursor: default;
	color: #1E88E5;
	border: 1px solid transparent;
	background: transparent;
	box-shadow: none; }

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: #fff;
	background-color: #1E88E5; }

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	outline: none;
	background-color: #1E88E5; }

.dataTables_wrapper .dataTables_paginate .ellipsis {
	padding: 0 1em; }

div.dt-button-info {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 400px;
	margin-top: -100px;
	margin-left: -200px;
	background-color: white;
	border: 2px solid #607D8B;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	text-align: center;
	z-index: 2100; }
	div.dt-button-info h2 {
		padding: 0.5em;
		margin: 0;
		font-weight: normal;
		border-bottom: 1px solid #ddd;
		background-color: #f3f3f3; }
		div.dt-button-info h2:before {
			content: "\f00c";
			font-family: FontAwesome;
			color: #4CAF50;
			margin-right: 8px; }
	div.dt-button-info > div {
		padding: 1em; }

table.dataTable th.focus,
table.dataTable td.focus {
	outline: 3px solid #1E88E5;
	outline-offset: -1px; }
*/
/**
 * FooTable
 */
.footable-filtering > th {
	border: 0; }

.footable-filtering form {
	float: right; }

.footable-filtering .input-group-btn {
	flex-direction: row; }
	.footable-filtering .input-group-btn .btn-default, .footable-filtering .input-group-btn .btn-secondary {
		background: #fff; }

.footable-filtering .open > .dropdown-menu {
	display: block;
	color: inherit; }
	.footable-filtering .open > .dropdown-menu > li {
		padding: .6rem 1.2rem; }
		.footable-filtering .open > .dropdown-menu > li:hover {
			background-color: rgba(160, 160, 160, 0.25); }

/**
 * Tables Responsive
 */
@media only screen and (max-width: 991px) {
	.no-more-tables table,
	.no-more-tables thead,
	.no-more-tables tbody,
	.no-more-tables th,
	.no-more-tables td,
	.no-more-tables tr {
		display: block; }
	.no-more-tables thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px; }
	.no-more-tables tr {
		border: 1px solid #ccc; }
	.no-more-tables td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
		white-space: normal;
		text-align: left; }
	.no-more-tables td:before {
		position: absolute;
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		text-align: left;
		font-weight: bold; }
	/*
		Label the data
		*/
	.no-more-tables td:before {
		content: attr(data-title); } }

/**
 * Utilities
 */
.fh {
	height: 100% !important; }

@media only screen and (min-width: 768px) {
	.fh-sm {
		height: 100% !important; } }

@media only screen and (min-width: 992px) {
	.fh-md {
		height: 100% !important; } }

@media only screen and (min-width: 1200px) {
	.fh-lg {
		height: 100% !important; } }

.fw {
	width: 100% !important; }

@media only screen and (min-width: 768px) {
	.fw-sm {
		width: 100% !important; } }

@media only screen and (min-width: 992px) {
	.fw-md {
		width: 100% !important; } }

@media only screen and (min-width: 1200px) {
	.fw-lg {
		width: 100% !important; } }

.va-middle {
	vertical-align: middle !important; }

.va-top {
	vertical-align: top !important; }

.va-bottom {
	vertical-align: bottom !important; }

table.va-middle td {
	vertical-align: middle !important; }

table.va-top td {
	vertical-align: top !important; }

table.va-bottom td {
	vertical-align: bottom !important; }

.b0 {
	border-width: 0 !important; }

.bl0 {
	border-left-width: 0 !important; }

.br0 {
	border-right-width: 0 !important; }

.bt0 {
	border-top-width: 0 !important; }

.bb0 {
	border-bottom-width: 0 !important; }

.br, .b, .ba {
	border-right: 1px solid rgba(162, 162, 162, 0.32); }

.bl, .b, .ba {
	border-left: 1px solid rgba(162, 162, 162, 0.32); }

.bt, .b, .ba {
	border-top: 1px solid rgba(162, 162, 162, 0.32); }

.bb, .b, .ba {
	border-bottom: 1px solid rgba(162, 162, 162, 0.32); }

.spr {
	margin-right: .25em; }

.spl {
	margin-left: .25em; }

.text-white {
	color: #fff; }

.text-alpha {
	color: rgba(255, 255, 255, 0.5); }

.text-alpha-inverse {
	color: rgba(0, 0, 0, 0.5); }

.text-gray-darker {
	color: #263238; }

.text-gray-dark {
	color: #455A64; }

.text-gray {
	color: #607D8B; }

.text-gray-light {
	color: #90A4AE; }

.text-gray-lighter {
	color: #ECEFF1; }

.text-inherit {
	color: inherit !important; }

.text-soft {
	color: rgba(162, 162, 162, 0.66); }

.text-light {
	color: rgba(255, 255, 255, 0.74); }

.text-color {
	color: #59676b; }

.text-sm {
	font-size: 0.85rem; }

.text-md {
	font-size: 1.9rem; }

.text-lg {
	font-size: 3rem; }

.text-xl {
	font-size: 10rem; }

.text-nowrap {
	white-space: nowrap; }

.text-thin {
	font-weight: 100 !important; }

.text-bold {
	font-weight: bold !important; }

.text-normal {
	font-weight: normal !important; }

.text-normal p {
	font-weight: normal; }

.text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: bottom; }

.inline {
	display: inline-block !important; }

.block-center {
	margin: 0 auto; }

.block {
	display: block !important; }

.bg-white {
	background-color: #fff;
	color: inherit !important; }

.bg-primary {
	background-color: #1E88E5 !important;
	color: #fff !important; }

.bg-primary .text-muted {
	color: rgba(255, 255, 255, 0.5) !important; }

.bg-primary small {
	color: inherit; }

.bg-success {
	background-color: #4CAF50 !important;
	color: #fff !important; }

.bg-success .text-muted {
	color: rgba(255, 255, 255, 0.5) !important; }

.bg-success small {
	color: inherit; }

.bg-info {
	background-color: #03A9F4 !important;
	color: #fff !important; }

.bg-info .text-muted {
	color: rgba(255, 255, 255, 0.5) !important; }

.bg-info small {
	color: inherit; }

.bg-warning {
	background-color: #FF9800 !important;
	color: #fff !important; }

.bg-warning .text-muted {
	color: rgba(255, 255, 255, 0.5) !important; }

.bg-warning small {
	color: inherit; }

.bg-danger {
	background-color: #FF5252 !important;
	color: #fff !important; }

.bg-danger .text-muted {
	color: rgba(255, 255, 255, 0.5) !important; }

.bg-danger small {
	color: inherit; }

.bg-gray-darker {
	background-color: #263238;
	color: #fff !important; }

.bg-gray-dark {
	background-color: #455A64;
	color: #fff !important; }

.bg-gray {
	background-color: #607D8B;
	color: #59676b !important; }

.bg-gray-light {
	background-color: #90A4AE;
	color: #59676b !important; }

.bg-gray-lighter {
	background-color: #ECEFF1;
	color: #59676b !important; }

.bg-transparent {
	background-color: transparent !important; }

.bg-fade {
	background-color: transparent !important;
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5) 55%, black);
	background-repeat: no-repeat; }

.bg-cover {
	background-size: cover; }

.bg-full {
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed; }

.bg-center {
	background-position: center center;
	background-size: cover; }

.thumb8 {
	width: 8px !important;
	height: 8px !important; }

.thumb16 {
	width: 16px !important;
	height: 16px !important; }

.thumb24 {
	width: 24px !important;
	height: 24px !important; }

.thumb32 {
	width: 32px !important;
	height: 32px !important; }

.thumb40 {
	width: 40px !important;
	height: 40px !important; }

.thumb48 {
	width: 48px !important;
	height: 48px !important; }

.thumb64 {
	width: 64px !important;
	height: 64px !important; }

.thumb80 {
	width: 80px !important;
	height: 80px !important; }

.thumb96 {
	width: 96px !important;
	height: 96px !important; }

.thumb128 {
	width: 128px !important;
	height: 128px !important; }

.lh1 {
	line-height: 1; }

.lh8 {
	line-height: 8px; }

.lh16 {
	line-height: 16px; }

.lh24 {
	line-height: 24px; }

.lh32 {
	line-height: 32px; }

.lh40 {
	line-height: 40px; }

.lh48 {
	line-height: 48px; }

.lh64 {
	line-height: 64px; }

.lh80 {
	line-height: 80px; }

.lh96 {
	line-height: 96px; }

.lh128 {
	line-height: 128px; }

.square8 {
	display: block;
	width: 8px !important;
	height: 8px !important; }

.square16 {
	display: block;
	width: 16px !important;
	height: 16px !important; }

.square24 {
	display: block;
	width: 24px !important;
	height: 24px !important; }

.square32 {
	display: block;
	width: 32px !important;
	height: 32px !important; }

.square40 {
	display: block;
	width: 40px !important;
	height: 40px !important; }

.square48 {
	display: block;
	width: 48px !important;
	height: 48px !important; }

.square64 {
	display: block;
	width: 64px !important;
	height: 64px !important; }

.square80 {
	display: block;
	width: 80px !important;
	height: 80px !important; }

.square96 {
	display: block;
	width: 96px !important;
	height: 96px !important; }

.square128 {
	display: block;
	width: 128px !important;
	height: 128px !important; }

.initial8 {
	display: block;
	width: 8px !important;
	height: 8px !important;
	display: inline-block;
	line-height: 8px;
	text-align: center;
	border-radius: 50%; }

.initial16 {
	display: block;
	width: 16px !important;
	height: 16px !important;
	display: inline-block;
	line-height: 16px;
	text-align: center;
	border-radius: 50%; }

.initial24 {
	display: block;
	width: 24px !important;
	height: 24px !important;
	display: inline-block;
	line-height: 24px;
	text-align: center;
	border-radius: 50%; }

.initial32 {
	display: block;
	width: 32px !important;
	height: 32px !important;
	display: inline-block;
	line-height: 32px;
	text-align: center;
	border-radius: 50%; }

.initial40 {
	display: block;
	width: 40px !important;
	height: 40px !important;
	display: inline-block;
	line-height: 40px;
	text-align: center;
	border-radius: 50%; }

.initial48 {
	display: block;
	width: 48px !important;
	height: 48px !important;
	display: inline-block;
	line-height: 48px;
	text-align: center;
	border-radius: 50%; }

.initial64 {
	display: block;
	width: 64px !important;
	height: 64px !important;
	display: inline-block;
	line-height: 64px;
	text-align: center;
	border-radius: 50%; }

.initial80 {
	display: block;
	width: 80px !important;
	height: 80px !important;
	display: inline-block;
	line-height: 80px;
	text-align: center;
	border-radius: 50%; }

.initial96 {
	display: block;
	width: 96px !important;
	height: 96px !important;
	display: inline-block;
	line-height: 96px;
	text-align: center;
	border-radius: 50%; }

.initial128 {
	display: block;
	width: 128px !important;
	height: 128px !important;
	display: inline-block;
	line-height: 128px;
	text-align: center;
	border-radius: 50%; }

.table-fixed {
	table-layout: fixed;
	width: 100%; }

.shadow-clear {
	box-shadow: 0 0 0 #000 !important; }

.radius-clear {
	border-radius: 0 !important; }

.item-grab {
	cursor: -webkit-grab;
	cursor: -moz-grab; }

.item-grabbing {
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing; }

.clickable {
	cursor: pointer !important; }

.rel-wrapper {
	position: relative !important; }

.oh {
	overflow: hidden; }

.oa {
	overflow: auto; }

@media only screen and (max-width: 767px) {
	.h-scroll {
		max-width: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch; }
		.h-scroll > .row {
			flex-wrap: nowrap; } }

.v-scroll-wrapper {
	max-height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch; }

.icon-18 {
	font-size: 18px;
	vertical-align: -18%; }

.icon-24 {
	font-size: 24px;
	vertical-align: -24%; }

.icon-36 {
	font-size: 36px;
	vertical-align: -36%; }

.icon-48 {
	font-size: 48px;
	vertical-align: -48%; }

.icon-2x {
	font-size: 2em; }

.icon-3x {
	font-size: 3em; }

.icon-4x {
	font-size: 4em; }

.icon-5x {
	font-size: 5em; }

.icon-lg {
	font-size: 1.33333333em;
	line-height: 0.75em;
	vertical-align: -15%; }

.icon-fw {
	display: inline-block;
	width: 1.28571429em;
	text-align: center; }

.icon-dark {
	color: rgba(0, 0, 0, 0.54); }
	.icon-dark.icon-inactive {
		color: rgba(0, 0, 0, 0.26); }

.icon-light {
	color: white; }
	.icon-light.icon-inactive {
		color: rgba(255, 255, 255, 0.3); }

.wd-xxs2 {
	width: 30px; }

.wd-xxs {
	width: 60px; }

.wd-xs {
	width: 90px; }

.wd-sm {
	width: 150px; }

.wd-sd {
	width: 200px; }

.wd-md {
	width: 240px; }

.wd-lg {
	width: 280px; }

.wd-xl {
	width: 320px; }

.wd-xxl {
	width: 360px; }

.wd-wide {
	width: 100%; }

.wd-auto {
	width: auto; }

.wd-zero {
	width: 0; }

.mw-50 {
	max-width: 50%; }

.mh-50 {
	max-height: 50%; }

.hg-xs {
	height: 30px; }

.hg-sm {
	height: 60px; }

.hg-sd {
	height: 90px; }

.hg-md {
	height: 120px; }

.hg-lg {
	height: 150px; }

.hg-xl {
	height: 200px; }

.link-unstyled {
	text-decoration: none !important;
	outline: none !important; }

textarea.no-resize {
	resize: none;
	max-width: 100%;
	min-width: 100%; }

.shadow-z0 {
	box-shadow: 0 0 0 #000; }

.shadow-z1 {
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }

.shadow-z2 {
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014); }

.shadow-z3 {
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.14), 0 6px 10px 0 rgba(0, 0, 0, 0.098), 0 1px 18px 0 rgba(0, 0, 0, 0.014); }

.shadow-z4 {
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.14), 0 8px 10px 1px rgba(0, 0, 0, 0.098), 0 3px 14px 2px rgba(0, 0, 0, 0.014); }

.shadow-z5 {
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.14), 0 16px 24px 2px rgba(0, 0, 0, 0.098), 0 6px 30px 5px rgba(0, 0, 0, 0.014); }

.image-list > a {
	margin-left: -8px; }
	.image-list > a:first-child {
		margin-left: 0; }
	.image-list > a:last-of-type {
		margin-right: 8px; }

.list-group-unstyle > .list-group-item {
	border-left: 0;
	border-right: 0; }
	.list-group-unstyle > .list-group-item:first-child {
		border-top-left-radius: 0;
		border-top-right-radius: 0; }
	.list-group-unstyle > .list-group-item:last-child {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0; }

.icons-list {
	margin: 40px auto;
	padding-left: 0;
	width: 100%;
	list-style: none;
	text-align: center;
	font-size: 1px; }
	.icons-list > li {
		position: relative;
		z-index: 0;
		display: inline-block;
		padding: 22px;
		width: 84px;
		border-radius: 4px;
		list-style: none;
		text-align: center;
		font-weight: normal;
		font-size: 32px;
		cursor: pointer; }
		.icons-list > li:hover {
			background-color: transparent;
			color: #03A9F4;
			transition: all .2s ease;
			transform: scale(2); }

.bg-pic1 {
	background-image: url("../img/pic1.jpg"); }

.bg-pic2 {
	background-image: url("../img/pic2.jpg"); }

.bg-pic3 {
	background-image: url("../img/pic3.jpg"); }

.bg-pic4 {
	background-image: url("../img/pic4.jpg"); }

.bg-pic5 {
	background-image: url("../img/pic5.jpg"); }

.bg-pic6 {
	background-image: url("../img/pic6.jpg"); }

.reader-block p {
	margin: 16px auto;
	font-size: 16px;
	line-height: 28px;
	font-weight: normal; }
	.reader-block p > img {
		max-width: 100%;
		margin: 32px auto; }

.box-placeholder {
	margin-bottom: 15px;
	padding: 20px;
	border: 1px dashed rgba(162, 162, 162, 0.26);
	background: transparent;
	color: inherit; }
	.box-placeholder > :last-child {
		margin-bottom: 0; }
	.box-placeholder.box-placeholder-lg {
		padding-top: 80px;
		padding-bottom: 80px; }

.bg-gradient-secondary {
	background-image: -webkit-linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #B0BEC5 0, #78909C 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.bg-gradient-primary {
	background-image: -webkit-linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #673AB7 0, #2196F3 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.bg-gradient-info {
	background-image: -webkit-linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #009688 0, #42A5F5 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.bg-gradient-success {
	background-image: -webkit-linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #CDDC39 0, #4CAF50 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.bg-gradient-danger {
	background-image: -webkit-linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #7B1FA2 0, #F44336 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.bg-gradient-warning {
	background-image: -webkit-linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(135deg, #FDD835 0, #FF9800 100%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
