.dataTable th {
    border-top: 0 !important;
}

.card-body.p0 {
	padding:0 !important;
}

.mark, mark {
    padding: .2em 0;
    background-color: #e0bc00;
}

.overflow-x-scroll {
	overflow-x: scroll;
}

.nowrap {
    white-space: nowrap;
}

.p0 .dataTables_wrapper {
	padding: 0;
}

.btn-outline-orange {
    color: #43b02b;
    background-color: transparent;
    background-image: none;
    border-color: #43b02b;
}
.btn-outline-orange:hover {
    color: #fff;
    background-color: #43b02b;
    border-color: #43b02b;
}

.btn-outline-orange.active, .btn-outline-orange:active, .show>.btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #43b02b;
    border-color: #2f811d;
}

.btn-outline-orange.focus, .btn-outline-orange:focus {
    box-shadow: 0 0 0 3px rgba(0, 217, 7, 0.57);
}

